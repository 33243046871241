import { Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

// Pages & Components
// Level 1 Pages
import Home from './pages/Home';
import Jobs from './pages/Jobs';
import Businesses from './pages/Businesses'

// level 2 Pages
import JobListingForm from './pages/JobListing/JobLisitingForm';
import SignupForm from './pages/Onboarding/SignupForm';

// Components
import DynamicNavbar from './components/Navbar/DynamicNavbar';
import DynamicFooter from './components/Footer/DynamicFooter';
import ProtectedRoute from './components/ProtectedRoute';
import StickyMenu from './components/StickyMenu';
import RevealAnimation from './assets/Cosmetics/animations/revealAnimation';
import BusinessListing from './pages/BusinessListing/BusinessListing';

// Contexts
import { JobFormProvider } from './context/JobFormContext';
import { useAuthContext } from './hooks/useAuthContext';

function App() {
  const { user } = useAuthContext()
  const [isMapHidden, setIsMapHidden] = useState(true);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  
  const routeConfig = [
    { path: '/', component: Home, props: { setIsSignUpOpen } },
    { path: '/joblisting', component: Jobs, props: { isMapHidden} },
    { path: '/businesslisting', component: Businesses, props: { isMapHidden }},


    { path: '/list-jobs/job-form', component: JobListingForm, requiredStep: 0, isProtected: true },
    { path: '/list-business/business-form', component: BusinessListing, forBusinessListing: true, authorized: user !== null, isProtected: true},
    { path: '/onboarding/account-register', component: SignupForm, forOnboarding: true, isProtected: false },
  ];

  return (
    <div className="App">
      <div vaul-drawer-wrapper="" className='pages bg-white mt-[-0.5vh]'>
        <JobFormProvider>
          <DynamicNavbar isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen}/>
          <Routes>
            {routeConfig.map(({ path, component: Component, isProtected, ...rest }) => {
              const routeElement = isProtected
                ? <ProtectedRoute component={Component} {...rest} />
                : <Component {...rest.props} />;

              return <Route key={path} path={path} element={routeElement} />;
            })}
          </Routes>
          <StickyMenu onToggleMap={() => setIsMapHidden(!isMapHidden)}/>
          <DynamicFooter isMapHidden={isMapHidden}/>
        </JobFormProvider>
      </div>
      < RevealAnimation/>
    </div>
  );
}

export default App;

