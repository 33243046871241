import React, { useState } from 'react';

const CountryCodeSelector = ({ onCountryChange }) => {
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    onCountryChange(countryCode);
  };

  return (
    <select className='pl-1 w-[50%] focus:border-none focus:outline-none focus:ring-0' value={selectedCountry} onChange={handleCountryChange}>
      <option value="">Select a country</option>
      <option value="81">Japan (+81)</option>
      <option value="61">Australia (+61)</option>
    </select>
  );
};

export default CountryCodeSelector;