import React, { useEffect, useRef } from 'react';

const MultiRangeSlider = ({ setMinRange, setMaxRange }) => {
  const inputLeftRef = useRef(null);
  const inputRightRef = useRef(null);
  const thumbLeftRef = useRef(null);
  const thumbRightRef = useRef(null);
  const rangeRef = useRef(null);

  useEffect(() => {
    const setLeftValue = () => {
      const inputLeft = inputLeftRef.current;
      const min = parseInt(inputLeft.min);
      const max = parseInt(inputLeft.max);
      setMinRange(inputLeft.value)

      inputLeft.value = Math.min(parseInt(inputLeft.value), parseInt(inputRightRef.current.value) - 1);

      const percent = ((inputLeft.value - min) / (max - min)) * 100;

      thumbLeftRef.current.style.left = `${percent}%`;
      rangeRef.current.style.left = `${percent}%`;
    };

    const setRightValue = () => {
      const inputRight = inputRightRef.current;
      const min = parseInt(inputRight.min);
      const max = parseInt(inputRight.max);
      setMaxRange(inputRight.value)

      inputRight.value = Math.max(parseInt(inputRight.value), parseInt(inputLeftRef.current.value) + 1);

      const percent = ((inputRight.value - min) / (max - min)) * 100;

      thumbRightRef.current.style.right = `${100 - percent}%`;
      rangeRef.current.style.right = `${100 - percent}%`;
    };

    const inputLeft = inputLeftRef.current;
    const inputRight = inputRightRef.current;
    const thumbLeft = thumbLeftRef.current;
    const thumbRight = thumbRightRef.current;

    inputLeft.addEventListener('input', setLeftValue);
    inputRight.addEventListener('input', setRightValue);

    inputLeft.addEventListener('mouseover', () => thumbLeft.classList.add('hover'));
    inputLeft.addEventListener('mouseout', () => thumbLeft.classList.remove('hover'));
    inputLeft.addEventListener('mousedown', () => thumbLeft.classList.add('active'));
    inputLeft.addEventListener('mouseup', () => thumbLeft.classList.remove('active'));

    inputRight.addEventListener('mouseover', () => thumbRight.classList.add('hover'));
    inputRight.addEventListener('mouseout', () => thumbRight.classList.remove('hover'));
    inputRight.addEventListener('mousedown', () => thumbRight.classList.add('active'));
    inputRight.addEventListener('mouseup', () => thumbRight.classList.remove('active'));

    setLeftValue();
    setRightValue();

    return () => {
      inputLeft.removeEventListener('input', setLeftValue);
      inputRight.removeEventListener('input', setRightValue);

      inputLeft.removeEventListener('mouseover', () => thumbLeft.classList.add('hover'));
      inputLeft.removeEventListener('mouseout', () => thumbLeft.classList.remove('hover'));
      inputLeft.removeEventListener('mousedown', () => thumbLeft.classList.add('active'));
      inputLeft.removeEventListener('mouseup', () => thumbLeft.classList.remove('active'));

      inputRight.removeEventListener('mouseover', () => thumbRight.classList.add('hover'));
      inputRight.removeEventListener('mouseout', () => thumbRight.classList.remove('hover'));
      inputRight.removeEventListener('mousedown', () => thumbRight.classList.add('active'));
      inputRight.removeEventListener('mouseup', () => thumbRight.classList.remove('active'));
    };
     // eslint-disable-next-line
  }, []);

  return (
    <div className="middle">
      <div className="multi-range-slider">
        <input type="range" id="input-left" min="0" max="100" defaultValue="25" ref={inputLeftRef}/>
        <input type="range" id="input-right" min="0" max="100" defaultValue="75" ref={inputRightRef}/>

        <div className="slider">
          <div className="track"></div>
          <div className="range" ref={rangeRef}></div>
          <div className="thumb left" ref={thumbLeftRef}></div>
          <div className="thumb right" ref={thumbRightRef}></div>
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;