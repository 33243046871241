import GallerySlider from "../components/GallerySlider"
import subeteButton from "../assets/Cosmetics/svgs/subete.svg"
import wakariButton from "../assets/Cosmetics/svgs/wakari.svg"
import benriButton from '../assets/Cosmetics/svgs/benri.svg'
import anzenButton from "../assets/Cosmetics/svgs/anzen.svg"
import {ReactComponent as RightArrow } from "../assets/Cosmetics/svgs/nav-right.svg"
import { useAuthContext } from "../hooks/useAuthContext"

const Home = ({ setIsSignUpOpen }) => {
    const { user } = useAuthContext()

    const features = [
        {
            title: 'お店/食事のおすすめ',
            link: '/storelisting'
        },
        {
            title: '宿泊のおすすめ',
            link: '/houselisting'
        },
        {
            title: '求人',
            link: '/joblisting'
        },
        {
            title: 'イベント',
            link: '/eventlisting'
        }
    ];

    return (
        <div className="flex justify-center ">
            <main className="main-content">
                <div className='hero-section'> 
                    <section className="flex flex-col mt-[5vh] md:mt-[10%] pb-[5%] md:pb-[5%] justify-center items-center">
                        <div className='reveal flex flex-col sm:flex-row justify-center items-center'>
                            <p className='block text-[1.6rem] font-inter font-semi-bold md:text-[2.5rem] md:hidden'>食事・イベント・宿泊</p> 
                            <p className='hidden text-[1.6rem] font-inter font-semi-bold md:text-[2.5rem] md:block'>食事・イベント・宿泊・</p>
                            <p className='text-[1.6rem] font-inter font-semi-bold md:text-[2.5rem]'>求人・ビジネス</p>
                        </div>
                        <div className='reveal flex flex-col md:flex-row justify-center items-center'>
                            <p className='text-[1.6rem] md:text-[2.5rem]'>すべて</p>
                            <div className='flex'> 
                                <p className='text-[1.6rem] md:text-[2.5rem] font-inter font-bold text-red'>マンゲツ</p>
                                <p className='text-[1.6rem] md:text-[2.5rem] font-inter font-light'>で </p>
                            </div>
                        </div>
                        <div className='reveal flex mt-5 flex-col justify-center items-center'>
                            <p className='text-[0.75rem] md:text-[1.1rem]'>ブリスベン・ゴールドコースト・サンシャインコーストの</p>
                            <p className='text-[0.75rem] md:text-[1.1rem]'>日本人向け生活情報サイト</p>
                        </div>
                        {!user && 
                        <button onClick={() => setIsSignUpOpen(true)} className="flex flex-row reveal ustify-center items-center py-2 pl-5 pr-2 md:py-3 md:pl-8 md:pr-5 bg-red rounded-full font-medium text-white drop-shadow-sm text-[1rem] md:text-[1.3rem] mt-[8%] md:mt-[5%] hover:drop-shadow-lg transition-all duration-400 ease-in">
                            始める<RightArrow className="size-7"/>
                        </button>
                        }
                    </section>
                    <GallerySlider className="z-[0]" features={features}/>
                </div>
                <div className="information-section">
                    <section className="flex flex-col mt-[5vh] py-[10%] justify-center items-center px-[15vw]">
                        <p className='reveal text-[0.8rem] text-center md:text-[1.2rem]'>オーストラリアに関する最新情報を提供する、日本人居住者、旅行者、および留学生向けのプラットフォーム</p>
                        <div className="reveal grid grid-cols-2 md:grid-cols-4 gap-4 mt-[3vh] justify-items-center">
                            <img className='w-auto md:h-[3.8vh] h-[4.3vh] drop-shadow-sm opacity-60 hover:opacity-100 hover:drop-shadow-lg hover:cursor-pointer' src={subeteButton} alt="すべて" draggable="false"/>
                            <img className='w-auto md:h-[3.7vh] h-[4.1vh] drop-shadow-sm opacity-70 hover:opacity-100 hover:drop-shadow-lg hover:cursor-pointer' src={wakariButton} alt="分かりやすく" draggable="false"/> 
                            <img className='w-auto md:h-[3.5vh] h-[4.3vh] drop-shadow-sm opacity-50 hover:opacity-100 hover:drop-shadow-lg hover:cursor-pointer' src={benriButton} alt="便利に" draggable="false"/> 
                            <img className='w-auto md:h-[3.5vh] h-[4.1vh] drop-shadow-sm opacity-60 hover:opacity-100 hover:drop-shadow-lg hover:cursor-pointer' src={anzenButton} alt="安全に" draggable="false"/> 
                        </div>
                        <div className="flex overflow-x-auto scroll-smooth snap-x snap-mandatory scrollbar-hide">

                        </div>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default Home