import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer'

const DynamicFooter = ({ isMapHidden }) => {
    const location = useLocation();
    const isJobFormRoute = location.pathname.startsWith('/list-jobs/');
    const isBusinessForm = location.pathname.startsWith('/list-business/');
    const isOnboarding = location.pathname.startsWith('/onboarding');


    return (
        isJobFormRoute ||isBusinessForm || isOnboarding ? "" : <Footer isMapHidden={isMapHidden}/>);
};

export default DynamicFooter;