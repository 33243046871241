import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { JoblistingCategories, JoblistingPositions } from '../assets/categories/jobCategories';
import { ReactComponent as RightArrow } from '../assets/Cosmetics/svgs/Rarrow.svg';
import { ReactComponent as LeftArrow } from '../assets/Cosmetics/svgs/Larrow.svg';

const CategorySlider = ({ type, setSelectedCategory }) => {
    let categories;
    switch (type) {
        case 'joblisting':
            categories = JoblistingCategories;
            break;
        case 'jobPositions':
            categories = JoblistingPositions;
            break;
        default:
            categories = [];
    }

    const [focusIndex, setFocusIndex] = useState(null);
    const sliderRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);

 
    const handleArrowClick = (direction) => {
        const slider = sliderRef.current;
        if (!slider) return;
      
        const getResponsiveScrollAmount = () => {
            const screenWidth = window.innerWidth;
            let multiplier;
            
            if (screenWidth <= 480) { // Mobile devices
                multiplier = 10; // Scroll less on smaller screens
            } else if (screenWidth <= 768) { // Tablets
                multiplier = 20;
            } else { // Desktops
                multiplier = 30;
            }
            
            return parseFloat(getComputedStyle(document.documentElement).fontSize) * multiplier;
        };
            
        const scrollAmount = getResponsiveScrollAmount();
        const newScrollPosition = direction === 'left' ? slider.scrollLeft - scrollAmount : slider.scrollLeft + scrollAmount;
      
        slider.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth'
        });
    };

    const handleCategoryClick = (category, index) => {
        // Check if the category is already selected
        if (selectedCategoryIndex === index) {
            // If so, deselect it
            setSelectedCategoryIndex(null);
            setSelectedCategory(null);
            setFocusIndex(null)
        } else {
            // Otherwise, select the new category
            setSelectedCategoryIndex(index);
            setFocusIndex(index)
            setSelectedCategory(category);
        }
    };

    const checkScrollPosition = () => {
        const slider = sliderRef.current;
        if (!slider) return;
        
        const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
        setIsAtStart(slider.scrollLeft <= 0);
        setIsAtEnd(slider.scrollLeft >= maxScrollLeft - 1);
    };

    // Attach the event listener after the component mounts
    useEffect(() => {
        const slider = sliderRef.current;
        if (slider) {
            slider.addEventListener('scroll', checkScrollPosition, { passive: true });
        }
        
        // Clean up the event listener when the component unmounts
        return () => {
            if (slider) {
            slider.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    return (
        <div className='flex flex-row w-auto items-center justify-start'>
            {type !== 'jobPositions' && 
            <button
                onClick={() => handleArrowClick('left')}
                className={`absolute left-6 border-[1px] border-gray-200 rounded-full p-2 drop-shadow-sm hover:drop-shadow-lg bg-white ${isAtStart ? 'hidden' : 'hidden md:block'}`}
            >
                <LeftArrow className='h-[0.8em] w-[0.8em]' />
            </button>
            }
             <div ref={sliderRef} className="flex flex-row gap-1 md:gap-[0.6rem] overflow-x-auto md:overflow-hidden scrollbar-hide items-center scroll-smooth transition ease-in-out">
                {categories.map((item, index) => (
                    <button
                        onClick={() => handleCategoryClick(item.label, index)}
                        className={`px-6 py-2 rounded-full ${focusIndex === index ? `${type === 'joblisting' ? 'bg-red/20' : 'bg-orange-200'} border-none` : `${type === 'joblisting' ? 'bg-white' : 'bg-white'} border`}`}
                        key={index}
                    >
                        <p className={`text-sideways font-inter text-[0.8rem] ${focusIndex === index ? `${type === 'joblisting' ? 'text-red/50' : 'text-amber-500'} font-semi-bold` : `${type === 'joblisting' ? 'text-neutral-500' : 'text-neutral-500'}`}`}>{item.label}</p>
                    </button>
                ))}
            </div>
            {type !== 'jobPositions' &&
            <button
                onClick={() => handleArrowClick('right')}
                className={`absolute right-6 border-[1px] border-gray-200 rounded-full p-2 drop-shadow-sm hover:drop-shadow-lg bg-white ${isAtEnd ? 'hidden' : 'hidden md:block'}`}
            >
                <RightArrow className='h-[0.8em] w-[0.8em]' />
            </button>
            }
            
        </div>
    );
};

export default CategorySlider;




