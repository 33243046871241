import { BusinessContext } from "../context/BusinessContext";
import { useContext } from "react";

export const useBusinessContext = () => {
    const context = useContext(BusinessContext)

    if (!context) {
        throw Error('useBusinessContext must be used inside an BusinessContextProvider')
    }

    return context
}