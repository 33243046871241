import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as MapIcon } from '../assets/Cosmetics/svgs/mapicon.svg';
import { ReactComponent as SearchIcon } from '../assets/Cosmetics/svgs/search.svg';
import { ReactComponent as MenuIcon } from '../assets/Cosmetics/svgs/menuicon.svg'
import { ReactComponent as JobIcon } from '../assets/Cosmetics/svgs/joblisting.svg'
import { ReactComponent as BusinessIcon } from '../assets/Cosmetics/svgs/shoplisting.svg'
import { ReactComponent as HouseIcon } from '../assets/Cosmetics/svgs/houselisting.svg'
import { ReactComponent as EventIcon } from '../assets/Cosmetics/svgs/event.svg'
import { Link, useLocation } from 'react-router-dom';
import {
    Cross1Icon
} from '@radix-ui/react-icons'

import EventButton from '../assets/Cosmetics/webp/event-button.webp'
import JobButton from '../assets/Cosmetics/webp/job-button.webp'
import HousingButton from '../assets/Cosmetics/webp/house-button.webp'
import BusinessButtonm from '../assets/Cosmetics/webp/business-button.webp'

const StickyMenu = ({ onToggleMap }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMapOpen, setIsMapOpen] = useState(false)
    const location = useLocation()
    const [isVisible, setIsVisible] = useState(true);
    const { closeButton } = useRef()

    
    const mapPagesObj = {'/joblisting': true, '/businesslisting': true, '/housing': true, '/events': true};
    const mapPages = !!mapPagesObj[location.pathname];


    const formPagesObj = {
        '/list-jobs/job-form': true,
        '/list-business/business-form': true
    };

    const JobObj = {
        '/joblisting': true,
    }
    const JobPage = !!JobObj[location.pathname]

    const BusinessObj = {
        '/businesslisting': true,
    }
    const BusinessPage = !!BusinessObj[location.pathname]

    const HouseObj = {
        '/housing': true,
    }
    const HousePage = !!HouseObj[location.pathname]

    const EventOBj = {
        '/events': true,
    }
    const EventPage = !!EventOBj[location.pathname]

    const registerPage = {
        "/onboarding/account-register" :true,
    }

    const formPages = !!formPagesObj[location.pathname] || !!registerPage[location.pathname]; 

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.innerHeight + window.scrollY;
          const documentHeight = document.documentElement.scrollHeight;
    
          if (scrollPosition >= documentHeight) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = (ref) => {
        if (mapPages) {
            setIsMenuOpen(!isMenuOpen);
        } else {
            if (isMenuOpen && ref === closeButton) {
                setIsMenuOpen(false);
            } else {
                setIsMenuOpen(true);
            }
        }
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const MapToggle = () => {
        onToggleMap()
        setIsMapOpen(!isMapOpen)
    }
 
    return (
        <div className={`fixed bottom-[4vh] md:bottom-[5vh] left-1/2 -translate-x-1/2 z-[10] transition-opacity ease-in duration-600 ${isVisible ? 'opacity-100' : 'opacity-0'} `}>
                {/* Mobile Menu */}
                {!formPages && (
                <div className={`relative flex flex-row w-screen justify-items-center items-center`}>
                    <button onClick={() => {MapToggle()}} className={`fixed left-[4.5rem] -translate-y-1 bottom-0 flex justify-center items-center w-[4rem] h-[4rem] p-[0.5rem] drop-shadow-md border rounded-full z-[2] md:hidden shadow-inner transition-all duration-400 ease-out ${isMapOpen ? "bg-red" : !mapPages ? "bg-neutral-300 border-neutral-300 pointer-events-none" : "bg-white"} `}>
                        <MapIcon className={`h-[1.3rem] w-auto ${isMapOpen ? "menu-stroke" : ""}`} />
                    </button>
                    <div onClick={() => closeMenu()} className={`fixed top-[-100vh] left-1/2 -translate-x-1/2 ${isMenuOpen ? 'bg-black/0 h-[120vh] w-screen z-[2]' : ''}`}/>
                    <div className={`flex flex-col gap-1 justify-center items-center bg-white drop-shadow-md z-[3] md:hidden absolute left-1/2 -translate-x-1/2 bottom-[0rem] transition-form duration-150 ease-in-out ${isMenuOpen ? 'w-[22rem] h-[17rem] border rounded-[20px] cursor-default': 'border rounded-full shadow-inner w-[5rem] h-[5rem]'}`}>
                        <button className={`flex justify-center items-center h-full w-full rounded-full ${isMenuOpen ? "hidden" : "block"}`} onClick={() => toggleMenu()}>
                            {JobPage && 
                                <JobIcon className={`h-[1.7rem] w-auto red-stroke ${isMenuOpen ? "hidden" : "block"}`} /> 
                            }
                            {BusinessPage &&
                               <BusinessIcon className={`h-[1.7rem] w-auto red-stroke ${isMenuOpen ? "hidden" : "block"}`} />  
                            }
                            {HousePage && 
                                <HouseIcon className={`h-[1.7rem] w-auto red-stroke ${isMenuOpen ? "hidden" : "block"}`} /> 
                            }
                            {EventPage &&
                                <EventIcon className={`h-[1.7rem] w-auto red-stroke ${isMenuOpen ? "hidden" : "block"}`} /> 
                            }
                            {!mapPages &&
                                <MenuIcon className={`h-[1.7rem] w-auto ${isMenuOpen ? "hidden" : "block"}`} />
                            }
                        </button>
                        <div className={`relative px-[1rem] pb-[1rem] pt-[4rem] transition-all duration-1200 ease-in menu-container ${isMenuOpen ? "block w-full h-full" : "hidden w-[1] h-[1]"}`}>
                            <button ref={closeButton} onClick={() => closeMenu()}className='absolute top-5 right-5 m-0 p-0 rounded-full bg-neutral-100 p-2'>
                                    <Cross1Icon />
                            </button>
                            <p className='absolute left-6 top-5 text-[20px] font-medium'>メニュー</p>
                            <div className="grid grid-cols-2 gap-2 h-full w-full justify-center items-center border-t-[1px] pt-[1rem]">
                                <Link to="/events" onClick={closeMenu} className='flex justify-center items-center'>
                                    <img className="drop-shadow-lg" src={EventButton} alt="Events"/>
                                </Link>
                                <Link to="/joblisting" onClick={closeMenu} className='flex justify-center items-center'>
                                    <img className="drop-shadow-lg" src={JobButton} alt="Jobs"/>
                                </Link>
                                <Link to="/businesslisting" onClick={closeMenu} className='flex justify-center items-center'>
                                    <img className="drop-shadow-lg" src={BusinessButtonm} alt="Businesses"/>
                                </Link>
                                <Link to="/housing" onClick={closeMenu} className='flex justify-center items-center'>
                                    <img className="drop-shadow-lg" src={HousingButton} alt="Housing"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <button className="fixed right-[4.5rem] -translate-y-1 bottom-0 flex justify-center items-center w-[4rem] h-[4rem] p-[0rem] bg-white drop-shadow-md border rounded-full z-[2] md:hidden shadow-inner">
                        <SearchIcon className="h-[1.3rem] w-auto" />
                    </button>
                </div>
                )}
            
            {/* Desktop Menu */}
            {!formPages && (
                <div className='flex justify-center items-center gap-6'>
                    <div className='hidden relative flex flex-row gap-3 bg-slate-50 rounded-full z-[2] px-[1.5rem] py-[1rem] border md:flex drop-shadow-md'>
                        <button className='px-[1.5rem] py-[0.8rem] bg-white rounded-[30px] border drop-shadow-md hover:drop-shadow-lg transition ease'>イベント</button>
                        <Link to="/joblisting" className='px-[1.5rem] py-[0.8rem] bg-white rounded-[30px] border drop-shadow-md hover:drop-shadow-lg transition ease'>求人 </Link>
                        <Link to='/businesslisting' className='px-[1.5rem] py-[0.8rem] bg-white rounded-[30px] border drop-shadow-md hover:drop-shadow-lg transition ease'>ビジネス</Link>
                        <button className='px-[1.5rem] py-[0.8rem] bg-white rounded-[30px] border drop-shadow-md hover:drop-shadow-lg transition ease'>宿泊</button>
                    </div>
                    <button className="hidden flex justify-center items-center w-[4rem] h-[4rem] p-[0.5rem] bg-white drop-shadow-md border rounded-full z-[2] text-white md:flex shadow-inner hover:drop-shadow-lg">
                        <SearchIcon className="h-[1.3rem] w-auto" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default StickyMenu;
