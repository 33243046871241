import { useState, useEffect } from "react";
import PasswordStrengthBar from 'react-password-strength-bar';


const PasswordStep = ({ formData, setFormData }) => {
    const [password, setPassword] = useState('');
    const [isPasswordStrong, setIsPasswordStrong] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        } else {
            setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === "checkbox" ? checked : value,
            }));
        }
    };

    useEffect(() => {
        if (password !== confirmPassword) {
            setPasswordMatch(false);
            setFormData((prevFormData) =>({
                ...prevFormData,
                password: "",
            }))
        } else { 
            setPasswordMatch(true);
            if (!isPasswordStrong) {
            setFormData((prevFormData) =>({
                ...prevFormData,
                password: "",
            }))
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    password,
                }));
            }
        }
        
    }, [password, confirmPassword, isPasswordStrong, setFormData]);

    const customPhrases = ['とても弱い', 'やや弱い', '普通', '良い', '非常に強い'];

    return (
        <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-screen h-full md:w-[40vw]">
            <section className='flex flex-col gap-2 w-[80%] h-full'>
                <p className="text-[1.7rem] font-inter font-bold mb-5">パスワードとプライバシー</p>
                <section className='flex flex-col gap-1 mb-2'>
                    <p className="text-[1rem] font-inter font-medium text-neutral-800">パスワード</p>
                    <input
                        className='w-full h-[2rem] placeholder:text-neutral-400 pl-2 py-4 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                        type="password"
                        name="password"
                        placeholder="文字、数字、記号を組み合わせる"
                        value={password}
                        onChange={handleChange}
                    />
                    <PasswordStrengthBar password={password} scoreWords={customPhrases} onChangeScore={(score) => setIsPasswordStrong(score >= 3)}/>
                    {!isPasswordStrong && password && <p className="text-red text-[0.7rem] ">大文字、小文字、数字、記号を組み合わせた8文字以上のパスワードを設定してください</p>}
                </section>

                <section className='flex flex-col gap-1 mb-4'>
                    <p className="text-[1rem] font-inter font-medium text-neutral-800">パスワード再入力</p>
                    <input
                        className='w-full h-[2rem] placeholder:text-neutral-400 pl-2 py-4 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        placeholder='再入力'
                        onChange={handleChange}
                    />
                    {!passwordMatch && confirmPassword && <p className="text-red text-[0.7rem]">パスワードを一致させましょう</p>}
                </section>
                <label
                    className="flex items-center gap-4"
                    onClick={(e) => e.stopPropagation()}
                >
                    <input
                        type="checkbox"
                        name="privacy"
                        checked={formData.privacy}
                        className="sr-only peer"
                        onChange={handleChange}
                    />
                    <div
                        className="z-[2] cursor-pointer relative w-[3.8rem] h-[2rem] bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-red"
                        onClick={(e) => {
                            // No need to call e.stopPropagation() here
                        }}
                    ></div>
                    <span
                        className="text-sm font-medium text-gray-900 dark:text-gray-300"
                        onClick={(e) => e.preventDefault()}
                    >
                        プライバシーをオン
                    </span>
                    <div className="fixed w-full h-[2rem] z-[1]" onClick={(e) => e.preventDefault()}></div>
                </label>
            </section>
        </div>
    )
}

export default PasswordStep