import React, { useState, useEffect, useRef } from 'react';

const VerificationCodeInput = ({ onCodeChange }) => {
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);

    useEffect(() => {
        const handlePaste = (e) => {
            const pastedValue = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);
            if (pastedValue.length > 0) {
                const updatedInputValues = [...inputValues];
                for (let i = 0; i < 6; i++) {
                    updatedInputValues[i] = pastedValue[i] || '';
                }
                setInputValues(updatedInputValues);
                onCodeChange(pastedValue);
                inputRefs.current[pastedValue.length - 1].focus();
            }
        };

        document.addEventListener('paste', handlePaste);

        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [inputValues, onCodeChange]);

    const handleChange = (e, index) => {
        const newValue = e.target.value.replace(/\D/g, '');
        const updatedInputValues = [...inputValues];

        updatedInputValues[index] = newValue;
        const updatedCode = updatedInputValues.join('');
        setInputValues(updatedInputValues);
        onCodeChange(updatedCode);

        // Move focus to the next input box if the current one is filled
        if (newValue && index < 5) {
            inputRefs.current[index + 1].focus();
        } else if (e.target.value === '') {
            // Move focus to the previous input box if the current input is empty
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const renderInputBoxes = () => {
        return inputValues.map((value, index) => (
            <input
                key={index}
                type="text"
                maxLength={1}
                value={value}
                onChange={(e) => handleChange(e, index)}
                inputMode="numeric"
                className="border-[2px] rounded-[10px] h-[3rem] w-[2.5rem] text-center focus:border-red focus:outline-none focus:ring-0"
                ref={(el) => (inputRefs.current[index] = el)}
            />
        ));
    };

    return <div className="flex gap-2">{renderInputBoxes()}</div>;
};

export default VerificationCodeInput;