import { useEffect } from 'react';

const RevealAnimation = () => {
  useEffect(() => {
    function revealOnScroll() {
      var reveals = document.querySelectorAll(".reveal");

      reveals.forEach((element) => {
        var windowHeight = window.innerHeight;
        var elementTop = element.getBoundingClientRect().top;
        var elementVisible = 10;

        if (elementTop < windowHeight - elementVisible) {
          element.classList.add("active");
        } else {
          element.classList.remove("active");
        }
      });
    }

    function resetAnimations() {
      var reveals = document.querySelectorAll(".reveal");

      reveals.forEach((element) => {
        element.classList.remove("active");
      });

      revealOnScroll(); // Check initial scroll position
    }

    function handleLoad() {
      resetAnimations();
    }

    window.addEventListener("scroll", revealOnScroll);
    window.addEventListener("popstate", resetAnimations);

    // Trigger initial animations on component mount
    handleLoad();

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", revealOnScroll);
      window.removeEventListener("popstate", resetAnimations);
    };
  }, []);

  return null;
};

export default RevealAnimation;

