import { useLocation, Link } from 'react-router-dom'
import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import logolargewebp from '../../assets/Cosmetics/webp/logo-large.webp'
import logosmallwebp from '../../assets/Cosmetics/webp/logo-small.webp'
import LoginComponent from '../Auth/LoginComponent'
import SignUpComponent from '../Auth/SignUpComponent'
import AccountComponent from '../Account/AccountComponent'
import { motion } from 'framer-motion'
import { Drawer } from 'vaul'

import { ReactComponent as AccountIcon } from '../../assets/Cosmetics/svgs/account.svg'
import { ReactComponent as JobListingIcon } from '../../assets/Cosmetics/svgs/joblisting.svg'
import { ReactComponent as HouseListingIcon } from '../../assets/Cosmetics/svgs/houselisting.svg'
import { ReactComponent as BusinessListingIcon} from '../../assets/Cosmetics/svgs/shoplisting.svg'
import { ReactComponent as EventLisitngIcon} from '../../assets/Cosmetics/svgs/event.svg'
import { ReactComponent as EmptyIconSquare} from '../../assets/Cosmetics/svgs/emptypfp-square.svg'
import { ReactComponent as UploadIcon } from '../../assets/Cosmetics/svgs/upload.svg'
import { ReactComponent as CrossIcon } from '../../assets/Cosmetics/svgs/cross.svg'
import { ReactComponent as SecureCheck } from '../../assets/Cosmetics/svgs/shield-check.svg'

const Navbar = ({ isSignUpOpen, setIsSignUpOpen }) => {
    const { user } = useAuthContext()
    const location = useLocation()
    const [loginMode, setLoginMode] = useState(false)
    const [signinMode, setSigninMode] = useState(false)
    const [selectedButton, setSelectedButton] = useState('');
    const [postMode, setPostMode] = useState(false)
    const [jobPrevStage, setJobPrevStage] = useState(1)

    const mapPagesObj = {'/joblisting': true, '/businesslisting': true, '/housing': true, '/events': true};
    const mapPages = !!mapPagesObj[location.pathname];

    const handleLoginButton = () => {
        setLoginMode(!loginMode)
        setSigninMode(false)
    }

    const handleSigninButton = () => {
        setSigninMode(!signinMode)
        setLoginMode(false)
    }

    const DrawerClose = () => {
        setIsSignUpOpen(false)
        setLoginMode(false)
        setSigninMode(false)
    }

    const HandlePostDrawerClose = () => {
        setPostMode(false)
        setSelectedButton('')
        setJobPrevStage(1)
    }

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        setJobPrevStage(1)
    };

    const stepVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    }; 


    const BusinessPrevStep = () => {
        return (
            <div className='flex flex-col h-full justify-between'>
                <div>
                    <p className='text-[1.5rem] font-bold mb-2'>ビジネス投稿</p>
                    <p className='text-neutral-500'>投稿する準備と、投稿を印象的にするためのおすすめ</p>
                    <div className='flex flex-row justify-start items-center gap-2 border border-neutral-200 rounded-lg p-3 mt-5'>
                        <SecureCheck/>
                        <div className='flex flex-cols items-center font-semi-bold'>
                             <h1>ABN</h1>
                             <p></p>
                        </div>
                    </div>
                </div>
                <button onClick={() => window.location.href = '/list-business/business-form'} className='bg-red rounded-full w-full py-4 flex justify-center items-center text-white'>
                    つぎ
                </button>
            </div>
        )
    }
    

    const renderContent = () => {
        switch (selectedButton) {
        case '求人':
            return(
                <div className='flex flex-col h-full'>
                    <p className={`${jobPrevStage !== 3 ? "block" : "hidden"} text-[1.5rem] font-bold mb-2`}>求人投稿</p>
                    {jobPrevStage === 1 && (
                        <motion.div
                            className='h-full'
                            key="step-1"
                            variants={stepVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.2, ease: 'easeInOut'}}
                        >
                        <div className='flex flex-col h-full justify-between'>
                            <div>
                                <p className='text-[1.1rem] font-semi-bold text-neutral-500 mb-1'>
                                    ビジネスプロフィールを選択してください
                                </p>
                                <p className='text-[0.9rem] font-normal text-neutral-400'>
                                    ビジネスをアカウントにリンクしてない場合は、新しいビジネスプロフィールを作成してください。すでに御社のビジネスアカウントが作成されている場合は、個人アカウントとリンクする必要があります。
                                </p>
                            </div>
                            
                            <div className='flex justify-center items-center w-full h-full'>
                                <button onClick={() => setJobPrevStage(2)} >
                                    <EmptyIconSquare className="size-20"/>
                                </button>
                            </div>

                            <div className='flex flex-col gap-2 w-full justify-center items-center'>
                                <Link className='text-[0.8rem] font-normal text-blue-500 underline'>マンゲツでの投稿ポリシー</Link>
                                <button className='bg-red rounded-full w-full py-4 flex justify-center items-center text-white'>
                                    つぎ
                                </button>
                            </div>
                        </div>
                        </motion.div>
                    )}
                    
                    {jobPrevStage === 2 && (
                        <motion.div
                            className='h-full'
                            key="step-2"
                            variants={stepVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                        >
                        <div className='flex flex-col justify-between h-full'>
                            <div>
                                <p className='text-[1.1rem] font-semi-bold text-neutral-500 mb-3'>
                                    ビジネスを選択してください
                                </p>
                            </div>
                            
                            <button  onClick={() => setJobPrevStage(3)} className='flex justify-start items-center gap-2 text-neutral-400 font-medium pl-2 pb-2'>
                                <UploadIcon className="light-stroke"/> ビジネスプロフィールを作る
                            </button>
                            
                        </div>
                        </motion.div>
                    )}

                    {jobPrevStage === 3 && (
                        <motion.div
                            className='h-full'
                            key="step-2"
                            variants={stepVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                        >
                            <BusinessPrevStep />
                        </motion.div>
                    )}
                </div>
            );
        case 'ビジネス':
            return(
                 <BusinessPrevStep />
                );
        case '宿泊':
            return <div>宿泊 content goes here</div>;
        case 'イベント':
            return <div>イベント content goes here</div>;
        default:
            return null;
        }
    };

    return (
        <div vaul-drawer-wrapper="" className={`relative justify-center items-center w-screen z-[5] pt-5 md:pt-5 px-[4vw] ${mapPages ? "md:fixed top-0" : ""}`}>
            <div className={`bg-white pt-[0.6rem] md:px-6 md:pt-[1rem] md:pb-[0rem] transition-all duration-100 ease-linear ${mapPages ? "md:rounded-full md:drop-shadow-lg" : ""}`}>
                <div className="flex items-center justify-between pb-[0.6rem] md:pb-[1rem]">
                    <button onClick={() => window.location.href = '/'} className="flex items-center">
                        <img fetchpriority="high" width="128.65" height="40" src={logolargewebp} alt="Large Logo" className="h-10 hidden md:block"/>
                        <img fetchpriority="high" width="40" height="40" src={logosmallwebp} alt="Small Logo" className="h-10 md:hidden block"/> 
                    </button>

                    <div className='flex justify-end items-center space-x-4'>
                        <div>
                            {user ? (
                                <Drawer.Root open={postMode}>
                                {/* Account Drawer */}
                                <Drawer.Trigger onClick={() => setPostMode(true)} className='focus:outline-none focus:ring-0 text-[0.8rem] font-medium text-neutral-600 hover:text-neutral-700'>
                                    ＋ 投稿する
                                </Drawer.Trigger>
                                <Drawer.Portal>
                                    <Drawer.Overlay onClick={() => HandlePostDrawerClose()}className="fixed inset-0 bg-black/40 z-[10]" />
                                    <Drawer.Content className="flex justify-center fixed bottom-0 z-[11] focus:outline-none focus:ring-0 w-full md:pb-[5vh]">
                                        <div className={`flex bg-white p-5 w-full md:w-[25rem] md:rounded-[20px] rounded-t-[30px] transition-height duration-200 ease-in-out ${!selectedButton ? "h-[23vh]" : "h-[55vh]"}`}>
                                            <div className={`${selectedButton ? "hidden" : "flex flex-col justify-center items-start w-full h-full"}`}>
                                                <h1 className='text-[1.5rem] font-bold'>
                                                    投稿 
                                                </h1>
                                                <div className='flex flex-row justify-between items-between mt-4 w-full'>
                                                    <button
                                                        className="flex flex-col gap-1 items-center text-[0.65rem] font-medium text-neutral-600"
                                                        onClick={() => handleButtonClick('求人')}
                                                    >
                                                        <div className="p-7 rounded-[15px] bg-neutral-200">
                                                            <JobListingIcon className="size-6 default-stroke" />
                                                        </div>
                                                        求人
                                                    </button>
                                                    <button
                                                        className="flex flex-col gap-1 items-center text-[0.65rem] font-medium text-neutral-600"
                                                        onClick={() => handleButtonClick('ビジネス')}
                                                    >
                                                        <div className="p-7 rounded-[15px] bg-neutral-200">
                                                            <BusinessListingIcon className="size-6 default-stroke" />
                                                        </div>
                                                        ビジネス
                                                    </button>
                                                    <button
                                                        className="flex flex-col gap-1 items-center text-[0.65rem] font-medium text-neutral-600"
                                                        onClick={() => handleButtonClick('宿泊')}
                                                    >
                                                        <div className="p-7 rounded-[15px] bg-neutral-200">
                                                            <HouseListingIcon className="size-6 default-stroke" />
                                                        </div>
                                                        宿泊
                                                    </button>
                                                    <button
                                                        className="flex flex-col gap-1 items-center text-[0.65rem] font-medium text-neutral-600"
                                                        onClick={() => handleButtonClick('イベント')}
                                                    >
                                                        <div className="p-7 rounded-[15px] bg-neutral-200">
                                                            <EventLisitngIcon className="size-6 default-stroke" />
                                                        </div>
                                                        イベント
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className={`${
                                                !selectedButton ? 'hidden' : 'block'} relative w-full h-full`}>
                                                <button
                                                onClick={() => handleButtonClick(null)}
                                                className="absolute top-0 right-0 bg-neutral-200 rounded-full p-2"
                                                >
                                                <CrossIcon className="light-stroke size-3"/>
                                                </button>
                                                {renderContent()}
                                            </div>
                                        </div>
                                        
                                    </Drawer.Content>
                                </Drawer.Portal>
                            </Drawer.Root>
                            ) : (
                                <button onClick={() => setIsSignUpOpen(!isSignUpOpen)} className="text-neutral-600 hover:text-black font-semi-bold text-[0.8rem]">+ 投稿する</button>
                            )}
                        </div>
                        {user ? (

                            <Drawer.Root shouldScaleBackground>
                                {/* Account Drawer */}
                                <Drawer.Trigger className='focus:outline-none focus:ring-0'><img className='size-[5vh] rounded-full object-cover border-[1px]' src={user.pfp} alt="NaN"/> </Drawer.Trigger>
                                <Drawer.Portal>
                                    <Drawer.Overlay className="fixed inset-0 bg-black/40 z-[10]" />
                                    <Drawer.Content className="bg-white flex flex-col justify-center items-center rounded-t-[10px] h-[96%] fixed bottom-0 z-[11] focus:outline-none focus:ring-0">
                                        <AccountComponent /> 
                                    </Drawer.Content>
                                </Drawer.Portal>
                            </Drawer.Root>
                        ): (
                            <Drawer.Root open={isSignUpOpen}>
                                {/* Signup Drawer */}
                                <Drawer.Trigger asChild onClick={() => setIsSignUpOpen(!isSignUpOpen)}>
                                    <AccountIcon className='default-stroke w-auto size-[5vh] cursor-pointer'/>
                                </Drawer.Trigger>
                                <Drawer.Portal>
                                    <Drawer.Content className="fixed bottom-0 h-[100%] w-screen bg-white p-10 flex flex-col justify-center z-[12] focus:outline-none focus:ring-0">
                                            {!loginMode && !signinMode ? (
                                                    <div className='flex flex-col items-center mb-[5vh] md:mb-0'>
                                                        <img src={logosmallwebp} alt="Company Logo" className="h-20 w-auto" draggable="false"/> 
                                                        <section className='flex flex-row'>
                                                            <label className='font-bold mt-[2rem] text-red font-inter text-[1.6rem]'>マンゲツ</label> <label className='font-medium mt-[2rem] text-[1.6rem]'>へようこそ</label>
                                                        </section>
                                                        <button onClick={() => handleSigninButton()} className="mt-[1rem] rounded-[8px] bg-red text-white font-medium px-3 py-3 w-[15rem]" >登録する</button>
                                                        <button onClick={() => handleLoginButton()} className="mt-[1rem] rounded-[8px] bg-black text-white font-medium px-3 py-3 w-[15rem]" >ログイン</button>
                                                    </div>
                                            ) : (
                                                <></>
                                            )}
                                            <div>
                                                {loginMode ? (
                                                    <LoginComponent 
                                                        isSignUpOpen={isSignUpOpen} 
                                                        setIsSignUpOpen={setIsSignUpOpen}
                                                        loginMode={loginMode}
                                                        setLoginMode={setLoginMode}
                                                    />
                                                ) : (
                                                    <></>
                                                    
                                                )}
                                            </div>
                                            <div>
                                            {signinMode ? (
                                                    <SignUpComponent 
                                                        isSignUpOpen={isSignUpOpen} 
                                                        setIsSignUpOpen={setIsSignUpOpen}
                                                        signinMode={signinMode}
                                                        setSigninMode={setSigninMode}
                                                    />
                                            ) : (
                                                    <></>
                                            )}
                                            </div>
                                        
                                        <Drawer.Close asChild>
                                        <button onClick={() => DrawerClose()} className='absolute top-5 right-5 md:top-10 md:right-10 bg-neutral-200 rounded-full p-2'>
                                            <CrossIcon className="light-stroke size-3"/>
                                        </button>
                                        </Drawer.Close>
                                    </Drawer.Content>
                                </Drawer.Portal>
                            </Drawer.Root>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;

