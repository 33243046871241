import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar'

const DynamicNavbar = ({ isSignUpOpen, setIsSignUpOpen }) => {
    const location = useLocation();
    const isJobFormRoute = location.pathname.startsWith('/list-jobs/');
    const isBusinessForm = location.pathname.startsWith('/list-business/');
    const isOnboarding = location.pathname.startsWith('/onboarding');

    return (
        isJobFormRoute || isBusinessForm || isOnboarding ? <div/> : <Navbar isSignUpOpen={isSignUpOpen} setIsSignUpOpen={setIsSignUpOpen}/>);
};

export default DynamicNavbar;
