//Assets
import {ReactComponent as NavRight} from '../../assets/Cosmetics/svgs/nav-right.svg'
import {ReactComponent as EditIcon} from '../../assets/Cosmetics/svgs/edit.svg'


const UserDetails = ({ user, setPageState }) => {

    return (
        <div className="w-full flex flex-col items-center justify-center">
            <button className='relative flex justify-center items-center mb-5 w-[10vh]'>
                <img className='size-[10vh] rounded-full object-cover border-[1px]' src={user.pfp} alt="NaN"/> 
                <span className='absolute bottom-0 right-0 p-2 rounded-full bg-white border border-black'>
                    <EditIcon className='bg-white size-3'/>
                </span>
            </button>
            <div className='w-full flex flex-col gap-4'>
                <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700 border-b pb-4 border-neutral-100'>
                    ユーザーネーム
                    <div className='flex flex-row items-center gap-3 font-inter font-regular text-[1.5vh] text-neutral-400'>
                        @{user.username}
                        <NavRight className="fill-neutral-400 size-[2.5vh]"/>
                    </div>
                </button>
                <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700 border-b pb-4 border-neutral-100'>
                    電話番号
                    <div className='flex flex-row items-center gap-3 font-inter font-regular text-[1.5vh] text-neutral-400'>
                        {user.phone}
                        <NavRight className="fill-neutral-400 size-[2.5vh]"/>
                    </div>
                </button>
                <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700'>
                    Eメール
                    <div className='flex flex-row items-center gap-3 font-inter font-regular text-[1.5vh] text-neutral-400'>
                        {user.email}
                        <NavRight className="fill-neutral-400 size-[2.5vh]"/>
                    </div>
                    
                </button>
            </div>
        </div>
    )
}

export default UserDetails