import { JobFormContext } from "../context/JobFormContext";
import { useContext } from "react";

export const useJobFormContext = () => {
    const context = useContext(JobFormContext)

    if (!context) {
        throw Error('useJobFormContext must be used inside an JobFormProvider')
    }

    return context
}