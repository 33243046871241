import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const GallerySlider = ({ features }) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = features.length;

    const handleScroll = () => {
        const slider = sliderRef.current;
        if (slider) {
            const scrollLeft = slider.scrollLeft;
            const slideWidth = slider.offsetWidth; // Assuming each slide is full width of the slider
            const index = Math.round(scrollLeft / slideWidth);
            setCurrentSlide(index);
        }
    };

    useEffect(() => {
        const slider = sliderRef.current;

        const scrollItems = () => {
            if (slider) {
                const newSlideIndex = (currentSlide + 1) % totalSlides;
                setCurrentSlide(newSlideIndex);

                const scrollAmount = window.innerWidth * 0.7 * newSlideIndex;
                slider.scrollTo({ left: scrollAmount, behavior: 'smooth' });
            }
        };

        const intervalRef = setInterval(scrollItems, 15000);

        return () => clearInterval(intervalRef);
    }, [currentSlide, totalSlides]);

    const navigateToSlide = (index) => {
        setCurrentSlide(index);
        const scrollAmount = window.innerWidth * 0.7 * index;
        sliderRef.current.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    };

    return (
        <div className="reveal flex justify-center">
            <div className="relative overflow-hidden w-[100vw] mt-[5%] md:mt-0 md:w-[80vw]" >
                <div className="flex overflow-x-auto scroll-smooth snap-x snap-mandatory scrollbar-hide" ref={sliderRef} onScroll={handleScroll}>
                    {features.map((feature, index) => (
                        <div key={index} className="flex-none w-[100%] snap-center px-5">
                            <div className='flex items-end justify-between w-full'>
                                <h2 className='text-[1.1rem] md:text-[1.1rem] font-inter font-medium md:text-[1.5rem]'>{feature.title}</h2>
                                <Link className='text-[0.8rem] md:text-[0.9rem] font-inter font-light cursor-pointer' to={feature.link}>もっと見る</Link>
                            </div>
                            <div className='flex flex-col mt-4 gap-3 md:flex-row md:gap-4'>
                                <div className='flex-grow h-[30vh] bg-gray-200 rounded-[15px] md:h-[auto]'></div>
                                <div className='flex-grow'>
                                    <div className='flex flex-col gap-3 md:gap-4'>
                                        <div className='flex gap-3 md:gap-4'>
                                            <div className='flex-grow h-[18vh] bg-gray-200 rounded-[15px] md:h-[19vh]'></div>
                                            <div className='flex-grow h-[18vh] bg-gray-200 rounded-[15px] md:h-[19vh]'></div>
                                        </div>
                                        <div className='flex-grow h-[30vh] bg-gray-200 rounded-[15px] md:h-[19vh]'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center p-4">
                    {Array.from({ length: totalSlides }).map((_, index) => (
                        <button
                            key={index}
                            className={`h-2 md:h-2.5 mx-0.5 rounded-full transition-all duration-150 ease-linear ${
                                index === currentSlide 
                                    ? 'w-12 bg-gray-400'  // Active dot
                                    : 'w-2 md:w-2.5 bg-gray-400'  // Inactive dot
                            }`}
                            onClick={() => navigateToSlide(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default GallerySlider