import React, { useState } from 'react';
import VerificationCodeInput from './components/VerificationCodeInput';
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading-black.svg'

const EmailVerification = ({ formData, onVerificationSuccess }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false); // Add a state for loading

  const submitVerificationCode = async (code) => {
    setIsLoading(true); // Set loading state to true
    try {
      const response = await fetch(`${apiUrl}/api/user/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email, otp: code }),
      });
  
      if (response.ok) {
        onVerificationSuccess();
      } else {
        throw new Error('入力されたコードが正しくありません');
      }
      
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
        setIsLoading(false); // Set loading state to false
    }
  };

  const handleVerificationCodeChange = (newCode) => {
    // Check if the code length is 6 and submit the verification
    if (newCode.length === 6) {
      submitVerificationCode(newCode);
    }
  };

  return (
    <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-screen h-full md:w-[40vw]">
      <section className='flex flex-col gap-2 w-[80%] h-full'>
        <h2 className="text-[1.7rem] font-inter font-bold mb-3">確認ステップ</h2>
        <p className="text-[1rem] font-inter font-medium text-neutral-400 mb-3" >{formData.email} の受信トレイを確認し、確認コードを入力してください</p>
        <div className="relative flex flex-row items-center gap-4 mt-3">    
          <VerificationCodeInput
            onCodeChange={handleVerificationCodeChange}
          />
          {isLoading && (
            <LoadingSvg className='animate-spin size-5 focus-stroke'/>
          )}
        </div>
        
        {errorMessage && (
          <p className="text-red mt-2">{errorMessage}</p>
        )}
      </section>
    </div>
  );
};

export default EmailVerification;