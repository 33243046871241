export function abnLookup(abn) {
    return new Promise(function(resolve, reject) {
      // Generate a unique callback name
      var callbackName = 'abnCallback_' + Date.now() + '_' + Math.random().toString(36).substring(7);
  
      // Define the callback function on the window object
      window[callbackName] = function(data) {
        // Clean up the script element and callback function
        document.body.removeChild(script);
        delete window[callbackName];
  
        // Resolve the Promise with the data.Message
        resolve(data.Message);
      };
  
      var request = `https://abr.business.gov.au/json/AbnDetails.aspx?callback=${callbackName}&abn=${abn}&guid=458c09c4-aaea-45b0-8741-f5afaa0e583c`;
  
      // Create a script element
      var script = document.createElement('script');
      script.src = request;
  
      // Define the error handling function
      var handleError = function() {
        // Clean up the script element, callback function, and error event listener
        document.body.removeChild(script);
        delete window[callbackName];
        script.removeEventListener('error', handleError);
  
        // Reject the Promise if an error occurs
        reject(new Error('JSONP request failed'));
      };
  
      // Attach the error event listener to the script element
      script.addEventListener('error', handleError);
  
      // Append the script element to the document body
      document.body.appendChild(script);
    });
  }

    var fieldIdACN = 'TextBoxACN';
    var fieldIdEntityName = 'TextBoxEntityName';
    var fieldIdAbnStatus = 'TextBoxAbnStatus';
    var fieldIdAbnStatusDate = 'TextBoxAbnStatusDate';
    var fieldIdBusinessName = 'TextBoxBusinessName';
    var fieldIdBusinessNameList = 'ListBoxBusinessName';
    var fieldIdEntityTypeName = 'TextBoxEntityTypeName';
    var fieldIdEntityTypeCode = 'TextBoxEntityTypeCode';
    var fieldIdGst = 'TextBoxGst';
    var fieldIdAddressState = 'TextBoxAddressState';
    var fieldIdAddressPostcode = 'TextBoxAddressPostcode';
    var spinnerId = 'Spinner';

    export function abnCallback(abnData) {
        setFieldValue(fieldIdACN, abnData.Acn);
        setFieldValue(fieldIdEntityName, abnData.EntityName);
        setFieldValue(fieldIdAbnStatus, abnData.AbnStatus);
        setFieldValue(fieldIdAbnStatusDate, abnData.AbnStatusEffectiveFrom);
        setFieldValue(fieldIdEntityTypeName, abnData.EntityTypeName);
        setFieldValue(fieldIdEntityTypeCode, abnData.EntityTypeCode);
        setFieldValue(fieldIdGst, abnData.Gst);
        setFieldValue(fieldIdAddressPostcode, abnData.AddressPostcode);
        setFieldValue(fieldIdAddressState, abnData.AddressState);
    
        for (var i = 0; i < abnData.BusinessName.length; i++) {
        addValueToListBox(fieldIdBusinessNameList, abnData.BusinessName[i]);
        if (i === 0) {
            setFieldValue(fieldIdBusinessName, abnData.BusinessName[0]);
        }
        }
    
        if (abnData.Message.length > 0) {
        setFieldValue(fieldIdEntityName, abnData.Message);
        }
    
        document.getElementById(spinnerId).style.display = 'none';
    }

    function setFieldValue(fieldId, value) {
        var oField = document.getElementById(fieldId);
        if (oField) { 
            oField.value = value;
        }
    }

    function addValueToListBox(fieldId, value) {
        var oField = document.getElementById(fieldId);
        if (oField) {
        var o = document.createElement("option");
        o.text = value;
        o.value = value;
        oField.add(o);
        }
    }


