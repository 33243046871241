import React from 'react';
import classNames from 'classnames';
import * as Accordion from '@radix-ui/react-accordion';
import {
  ChevronDownIcon
} from "@radix-ui/react-icons"

const AccordionFilter = ({ children, className, count, title}) => (
  <Accordion.Root
    className={classNames(
      'focus:outline-none focus:ring-0',
      className
    )}
    type="single"
    collapsible
  >
    <AccordionItem value="item-1">
      <AccordionTrigger className="flex flex-row justify-between pl-1 text-neutral-500 gap-2 focus:outline-none focus:ring-0">
        <div className='flex flex-row gap-3 items-center'>
          <h1 className='text-black text-[1.2rem] font-inter font-semi-bold'>
            {title}
          </h1>
          <p>
            投稿数: {count}
          </p>
        </div>
        <div className='flex flex-row justify-center items-center gap-2 border border-neutral-200 rounded-full px-3 py-2'>
          フィルター<ChevronDownIcon
                    className="ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
                    aria-hidden
                  />
        </div>
        </AccordionTrigger>
      <AccordionContent>{children}</AccordionContent>
    </AccordionItem>
  </Accordion.Root>
);

const AccordionItem = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item
    className={classNames(
      'overflow-hidden',
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Item>
));

const AccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="flex">
    <Accordion.Trigger
      className={classNames(
        'group flex flex-1 cursor-pointer items-center justify-end bg-white text-[0.8rem] leading-none',
        className
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames(
      'text-mauve11 bg-mauve2 data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]',
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className="py-4">{children}</div>
  </Accordion.Content>
));

export default AccordionFilter;