import React, { createContext, useState, useEffect } from 'react'

export const JobFormContext = createContext()

export const JobFormProvider = ({ children }) => {
    const initialFormData = {
        _id:'',
        title: '',
        position: '',
        minRate: '',
        maxRate: '',
        location: '',
        locationLat: '',
        locationLong: '',
        image: '',
        category: '',
        businessName: '',
        benefits: [],
        description: '',
        isVisible: true,
        uploadState: ''
    }

    const [formData, setFormData] = useState(initialFormData)
    const [currentStep, setCurrentStep] = useState(0)
    const [ready, setReady] = useState(false)
 
    const updateFormData = (data) => {
        setFormData({ ...formData, ...data })
    }

    const resetFormData = () => {
        setFormData(initialFormData)
    }

    useEffect(() => {
        if (currentStep === 1) {
            setReady(formData.category !== '');
        }
        if (currentStep === 2) {
            setReady(formData.businessName !== '')
        }
        if (currentStep === 3) {
            setReady(formData.location !== '')
        }
        if (currentStep === 4) {
            setReady(formData.position !== '' && formData.maxRate !== '' && formData.minRate !== '')
        }
        if(currentStep === 5) {
            setReady(formData.benefits.length !== 0)
        }
        if (currentStep === 6) {
            setReady(formData.image !== '' && formData.title !== '' && formData.description !== '')
        }
    }, [currentStep, formData]);

    return (
        <JobFormContext.Provider value={{ formData, currentStep, ready, setReady, setCurrentStep, updateFormData, resetFormData }}>
            {children}
        </JobFormContext.Provider>
    )
}
