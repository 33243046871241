import React from 'react';
const ProgressIndicator = ({ currentStep, totalSteps}) => {

  return (
    <div className="flex justify-center p-4 absolute top-7 z-[10]">
                    {Array.from({ length: totalSteps }).map((_, index) => (
                        <div
                            key={index}
                            className={`h-1 md:h-1.5 mx-0.5 rounded-full transition-all duration-150 ease-linear ${
                                index === currentStep 
                                    ? 'w-8 bg-red'  // Active dot
                                    : 'w-4 md:w-4 bg-neutral-300'  // Inactive dot
                            }`}
                        />
                    ))}
    </div>
  );
};

export default ProgressIndicator;