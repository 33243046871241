import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { openSpring, closeSpring } from "../../../assets/Cosmetics/animations/springs";

import { ReactComponent as SaveIcon } from '../../../assets/Cosmetics/svgs/save.svg'
import { ReactComponent as RightArrow } from '../../../assets/Cosmetics/svgs/Rarrow.svg';
import { ReactComponent as LeftArrow } from '../../../assets/Cosmetics/svgs/Larrow.svg';

const BusinessCards = ({ business, toggleOpen }) => {
    const sliderRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleArrowClick = (direction) => {
        const slider = sliderRef.current;
        if (!slider) return;

        const slideWidth = slider.firstElementChild.offsetWidth;
        const slidesPerPage = 1;

        let newIndex;
        if (direction === 'left') {
            newIndex = currentIndex - slidesPerPage;
            if (newIndex < 0) {
                newIndex = 0;
            }
        } else {
            newIndex = currentIndex + slidesPerPage;
            if (newIndex >= business.photos.length) {
                newIndex = business.photos.length - slidesPerPage;
            }
        }

        const newScrollPosition = newIndex * (slideWidth + 17);

        slider.scrollTo({
            left: newScrollPosition,
            behavior: 'smooth'
        });

        setCurrentIndex(newIndex);
        checkScrollPosition();
    };

    const checkScrollPosition = () => {
        const slider = sliderRef.current;
        if (!slider) return;

        const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
        setIsAtStart(slider.scrollLeft <= 0);
        setIsAtEnd(slider.scrollLeft >= maxScrollLeft - 1);
    };

    useEffect(() => {
        const slider = sliderRef.current;
        if (slider) {
            slider.addEventListener('scroll', checkScrollPosition, { passive: true });
        }

        return () => {
            if (slider) {
                slider.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    const cardVariants = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0, transition: openSpring },
        exit: { opacity: 0, y: -50, transition: closeSpring },
    };

    const displayAddress = business.address.split(" ").slice(0, 4).join(" ");
    const singlePhoto = business.photos.length === 1;

    return (
        <motion.div
            variants={cardVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            layoutId={business._id}
            className={`flex flex-col bg-white border border-[1px] border-neutral-200 rounded-[15px] py-3 pl-3 drop-shadow-sm z-[4]`}
        >
            {/* upper */}
            <div className='relative flex flex-col rounded-[10px] w-full gap-1 md:h-[15rem] h-[14rem] mb-4'>
                {!singlePhoto && (
                    <button
                        onClick={() => handleArrowClick('left')}
                        className={`absolute left-4 top-1/2 -translate-y-1/2 z-[5] border-[1px] border-gray-200 rounded-full p-2 drop-shadow-sm hover:drop-shadow-lg bg-white ${isAtStart ? 'hidden' : 'hidden md:block'}`}
                    >
                        <LeftArrow className='h-[0.8em] w-[0.8em]' />
                    </button>
                )}
                <div ref={sliderRef} className='flex flex-row gap-2 md:gap-4 h-full overflow-x-auto pr-3 scrollbar-hide'>
                    {business.photos.map((item, index) => (
                        <img key={index} className={`object-cover ${singlePhoto ? "w-[100%]" : "w-[93%] md:w-[100%]"} border border-none rounded-[10px]`} src={item} alt={"pictures"} />
                    ))}
                </div>
                {!singlePhoto && (
                    <button
                        onClick={() => handleArrowClick('right')}
                        className={`absolute right-4 top-1/2 -translate-y-1/2 z-[5] border-[1px] border-gray-200 rounded-full p-2 drop-shadow-sm hover:drop-shadow-lg bg-white ${isAtEnd ? 'hidden' : 'hidden md:block'}`}
                    >
                        <RightArrow className='h-[0.8em] w-[0.8em]' />
                    </button>
                )}
            </div>
            {/* lower */}
            <div className='flex flex-row justify-between items-center w-full gap-3 pl-2 pb-2 pr-5'>
                <div className='flex flex-col pl-1 items-start h-full text-start text-[0.8rem] w-[75%]'>
                    <p className='text-neutral-400'>{displayAddress}</p>
                    <h1 className='text-[1.2rem] font-inter font-semi-bold'>{business.businessName}</h1>
                </div>
                <button className='flex justify-center items-center bg-red/20 rounded-full p-2'>
                    <SaveIcon className='size-auto red-stroke' />
                </button>
            </div>
        </motion.div>
    );
};

export default BusinessCards;