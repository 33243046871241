import React, { useState } from 'react';
import { useLogin } from '../../hooks/useLogin';
import smallLogo from '../../assets/Cosmetics/webp/logo-small.webp'
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading.svg'
import { ReactComponent as NavLeft } from '../../assets/Cosmetics/svgs/nav-left.svg'

const LoginComponent = ({ isSignUpOpen, setIsSignUpOpen, loginMode, setLoginMode }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const {login, error, isLoading} = useLogin()

  const handleSubmit = async (e) => {
    e.preventDefault()

    await login(email, password);

    setLoginMode(!loginMode)
    setIsSignUpOpen(!isSignUpOpen)
  }

  return (
    <div className='flex flex-col items-center justify-center h-[85vh] mb-[10vh] md:mb-0'>
      <button onClick={() => setLoginMode(!loginMode)} className='absolute top-8 left-8 p-2'>
          <NavLeft />
      </button> 
      <label className='text-[0.9rem] font-inter absolute top-9 text-center'>ログインする</label>
      <div className='flex flex-col h-[100vh] justify-center items-center'>
        <img src={smallLogo} alt="Company Logo" className="h-20 w-auto" draggable="false"/> 
        <label className='font-medium mt-[1rem] text-[1.6rem]'>お帰り！</label>
        <form className='mt-[1rem] flex flex-col justify-center items-center' onSubmit={handleSubmit}>
            <input
                type="email"
                placeholder='example@gmail.com'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className='border-[2px] rounded-[5px] px-3 py-1 w-[15rem] focus:border-red focus:outline-none focus:ring-0'
            />

            <input
                type="password"
                placeholder='Password'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className='mt-[1rem] border-[2px] rounded-[5px] px-3 py-1 w-[15rem] focus:border-red focus:outline-none focus:ring-0'
            />

            {error && <div className='error'>{error}</div>}
            <button className={`flex flex-row justify-center items-center gap-1 mt-[1rem] rounded-[5px] bg-black text-white font-medium px-3 py-2 w-[15rem]`} disabled={isLoading}>
              {isLoading && <LoadingSvg className='animate-spin h-5 w-5'/>} ログイン      
            </button>                
          </form>
        </div>
      
    </div>
  )
};

export default LoginComponent;

