import { useState, useEffect } from "react";
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import { validate } from 'react-email-validator';
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading-black.svg'
import { ReactComponent as CheckSvg } from '../../assets/Cosmetics/svgs/check.svg'
import { ReactComponent as WarningSvg } from '../../assets/Cosmetics/svgs/warning.svg'

const EmailStep = ({ formData, setFormData }) => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(true);
    const [debounceEmail] = useDebounce(email, 500);
    const apiUrl = process.env.REACT_APP_API_URL;

    const { isLoading, data: isEmailAvailable } = useQuery(
        ['checkEmailAvailability', debounceEmail],
        async () => {
            if (!debounceEmail) return true;

            const response = await fetch(`${apiUrl}/api/user/check-email?email=${debounceEmail}`);
            const data = await response.json();
            return data.available;
        },
        {
            enabled: !!debounceEmail,
            refetchOnWindowFocus: false,
        }
    );

    const handleChange = (e) => {
        const value = e.target.value;
    
        setEmail(value);
    };

    useEffect(() => {
        const isValidEmail = validate(email);

        if (!isValidEmail || !isEmailAvailable) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }, [email, isEmailAvailable])

    useEffect(() => {
        if (emailError) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: "",
            }))
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email,
            })) 
        }
        
        
    }, [email, emailError, setFormData]);


    return (
        <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-screen h-full md:w-[40vw]">
            <section className='flex flex-col gap-2 w-[80%] h-full'>
                <p className="text-[1.7rem] font-inter font-bold mb-5">メール登録</p>
                <section className='flex flex-col gap-1 mb-2'>
                    <p className="text-[1rem] font-inter font-medium text-neutral-800">Eメール</p>
                    <div className="relative flex flex-row items-center pr-6 justify-between">
                        <input
                            className='w-full h-[2rem] pl-2 py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                            type="text"
                            name="email"
                            value={email}
                            placeholder='example@gmail.com'
                            onChange={handleChange}
                        />
                        {isLoading ? (
                            <LoadingSvg className='animate-spin size-5 focus-stroke'/>
                        ) : !isEmailAvailable || emailError ? (
                            <WarningSvg className='size-5'/>
                        ) : (
                            <CheckSvg className='size-5'/>
                        )}
                    </div>
                </section>
            </section>
        </div>
    )
}

export default EmailStep

