import { useNavigate } from "react-router-dom"
import { useJobContext } from '../hooks/useJobsContext'
import { useJobFormContext } from "../hooks/useJobFormContext"
import { useEffect } from "react";

const FormNavigation = () => {
    const { dispatch } = useJobContext()
    const { ready, formData, currentStep, setCurrentStep, resetFormData } = useJobFormContext()
    const navigate = useNavigate()

    const jobListingSteps = {
        1: "/job-form/category",
        2: "/job-form/business-name",
        3: "/job-form/location",
        4: "/job-form/position-rates",
        5: "/job-form/benefits",
        6: "/job-form/details", 
    }

    useEffect(() => {
        window.addEventListener('popstate', function(event) {
            if (currentStep === 1) {
                navigate('/joblisting')
            }  else {
                let prevStep = currentStep - 1
                setCurrentStep(prevStep)
                navigate(jobListingSteps[prevStep])
            }
        });
    });

    const handleSubmit = async () => {

        const publish = {
            ...formData,
            uploadState: '7',
            isVisible: true
        }

        const apiUrl = process.env.REACT_APP_API_URL

        if (formData._id) {
            try {
                const response = await fetch(`${apiUrl}/api/jobs/` + formData._id, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(publish)
                });
    
                if (!response.ok) {
                    throw new Error('Server did not update the job visibility')
                } else {
                    resetFormData()
                    const json = await response.json()
                    setCurrentStep(1)
                    navigate('/joblisting')
                    dispatch({ type: 'UDATE_JOB', payload: json })
                } 
            } catch (error) {
                console.error(error);
                dispatch({ type: 'UPDATE_JOB', payload: formData })
            }
        }

        if (!formData._id) {
            const response = await fetch(`${apiUrl}/api/jobs`, {
                method: 'POST',
                body: JSON.stringify(publish),
                headers: {
                    'Content-Type': 'application/json',
                }
            })
    
            if (!response.ok) {
                console.log(response)
                const errorData = await response.json()
                console.error('Failed to submit the job:', errorData)
            } else {
                resetFormData()
                const json = await response.json()
                setCurrentStep(1)
                navigate('/joblisting')
                dispatch({ type: 'CREATE_JOB', payload: json })    
            }
        }
    }

    const handleNext = async () => {
        if (currentStep === 6) {
            handleSubmit()
        } else {
            let nextStep = currentStep + 1
            await setCurrentStep(nextStep)
            navigate(jobListingSteps[nextStep])
        }
    }

    const handleBack = async () => {
        if (currentStep === 1) {
            navigate('/joblisting')
        } else {
            let prevStep = currentStep - 1
            await setCurrentStep(prevStep)
            navigate(jobListingSteps[prevStep])
        }        
        
    }

    const proceedingButtonLabel = currentStep === 6 ? "投稿する" : "つぎ"

    return (
        <div className="flex flex-row gap-5">
            <button className="px-8 py-3 hover:underline hover:underline-offset-2" onClick={handleBack}>戻る</button>
            <button disabled={!ready} className={`px-8 py-3 text-white border rounded-[10px] hover:drop-shadow-md cursor-pointer disabled:cursor-not-allowed ${ready ? "bg-red" : "bg-gray-600 disable"}`} onClick={handleNext}>{proceedingButtonLabel}</button>
        </div>
    )
}

export default FormNavigation