import { useEffect, useState } from "react";
import { motion } from 'framer-motion'
import { useBusinessContext } from "../../hooks/useBusinessContext";
import { useAuthContext } from "../../hooks/useAuthContext";

//Steps
import LoadingScreen from "../Onboarding/components/LoadingScreen";

// Assets, Utils, Components and Contexts
import { ReactComponent as NavLeft } from '../../assets/Cosmetics/svgs/nav-left.svg';
import ProgressIndicator from '../../utils/ProgressIndicator';
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading.svg'
import RefreshPrompt from '../../utils/RefreshPrompt';
import BusinessDetalStep from "./BusinessDetailsStep";
import BusinessLocation from "./BusinessLocation";
import BusinessInfo from "./BusinessInfo";

const BusinessListing = () => {
    const {dispatch} = useBusinessContext()
    const { user } = useAuthContext()
    const [currentStep, setCurrentStep] = useState(0)
    const [ready, setReady] = useState(false);
    const[isListingLoading, setIsListingLoading] = useState(false)
    const[isLoading, ] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        logo: '',
        businessName: '',
        abn: '',
        phone: '',
        photos: [],
        description: '',
        category: '',
        website: '',
        address: '',
        locationLat: '',
        locationLong: '',
        user_id: user.user_id
      });

    const totalSteps = 3

    const apiUrl = process.env.REACT_APP_API_URL

    const SubmitBusiness = async (data) => {
        setIsListingLoading(true); 

        try {
            // Log the data object before sending the request
            console.log('Request Body:', data);
    
            const response = await fetch(`${apiUrl}/api/business/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            // Log the response object for debugging
            console.log('Response:', response);
    
            const business = await response.json();
    
            if (response.ok) {
                console.log('Upload Success');
                dispatch({ type: 'CREATE_BUSINESS', payload: business });
            } else {
                console.error('Upload fail');
            }
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setIsListingLoading(false);
        }
    };

    useEffect(() => {
        if (currentStep === 1) {
            setReady(
                formData.address !== ''
                && formData.locationLat !== ''
                && formData.locationLong !== ''
            );
        }
        if (currentStep === 2) {
            setReady(
            formData.photos.length !== 0
            && formData.description !== ''
            && formData.category !== '')
            }
    }, [currentStep, formData]);

    const stepVariants = {
        initial: { x: '5vw', opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: '-5vw', opacity: 0 },
    };

    const handleNext = async () => {
        if (currentStep === 2) {
            setCurrentStep(3)
            await SubmitBusiness({...formData})
            window.location.href = '/'
            setFormData({
                email: '',
                logo: '',
                businessName: '',
                abn: '',
                phone: '',
                photos: [],
                description: '',
                category: '',
                website: '',
                address: '',
                locationLat: '',
                locationLong: '' 
            })
        }
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        if (currentStep === 0) {
            window.location.href = '/';
        } else {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <div className="flex flex-col md:justify-center mt-8 md:mt-0 items-center px-10 h-[85vh]">
            
            {!(isListingLoading) && 
                <button onClick={handlePrevious} className="absolute top-8 left-8 p-2 z-[2]">
                    <NavLeft />
                </button>
            }
            {!isListingLoading && 
                <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />

            }
          <div className="flex flex-col justify-between items-center mt-[10vh] w-screen h-full">
                {currentStep === 0 && (
                <motion.div
                    className='h-full w-full flex justify-center px-14 mt-6'
                    key="step-0"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                    <BusinessDetalStep formData={formData} setFormData={setFormData} setReady={setReady}/>
                </motion.div>
                )}
                {currentStep === 1 && (
                <motion.div
                    className='h-full w-full mt-6'
                    key="step-1"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                    <BusinessLocation formData={formData} setFormData={setFormData}/>
                </motion.div>
                )}
                {currentStep === 2 && (
                <motion.div
                    className='h-full w-full px-14 mt-6'
                    key="step-2"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                    <BusinessInfo formData={formData} setFormData={setFormData}/>
                </motion.div>
                )}
                {isListingLoading && (
                  <LoadingScreen />
                )}
                {!(currentStep === 3 || isListingLoading) && 
                    <button
                    disabled={!ready || isLoading }
                    className={`fixed bottom-[4vh] flex flex-row justify-center items-center gap-2 rounded-full font-inter font-bold text-white py-5 w-[80%] md:w-[20vw] ${ready ? 'bg-red' : 'bg-neutral-500 disable'}`}
                    onClick={handleNext}
                    >
                    {isLoading && <LoadingSvg className='animate-spin h-5 w-5'/>} つぎ
                    </button>
                }
          </div>
          <RefreshPrompt isFormDirty={currentStep !== 3}/>
        </div>
      );
}

export default BusinessListing