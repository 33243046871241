import { useState, useEffect } from "react";
import ImageUploader from '../../components/ImageUploader'
import { abnLookup } from '../BusinessListing/components/AbnLookUp'
import { useDebounce } from 'use-debounce';


//Assets
import { ReactComponent as CheckSvg } from '../../assets/Cosmetics/svgs/check.svg'
import { ReactComponent as WarningSvg } from '../../assets/Cosmetics/svgs/warning.svg'
import { ReactComponent as UserSquareIcon } from '../../assets/Cosmetics/svgs/user-square.svg'
import {ReactComponent as CrossIcon } from '../../assets/Cosmetics/svgs/cross.svg'
 
const BusinessDetalStep = ({formData, setFormData, setReady}) => {
    const [businessName, setBusinessName] = useState(formData.businessName)
    const [nameError] = useState(false)

    //Name functions
    const handleChangeName = (e) => {
        const value = e.target.value;
    
        setBusinessName(value);
    };

    useEffect(() => {
        if (nameError) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                businessName: "",
            }))
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                businessName,
            })) 
        }
        
        
    }, [businessName, nameError, setFormData]);


    //ABN functions
    const[abn, setAbn ] = useState(formData.abn)
    const [abnError, setAbnError] = useState(false)
    const [debouncedAbn] = useDebounce(abn, 1000);

    const handleChangeAbn = (e) => {
        const value = e.target.value;
        setAbn(value);
      };
    
      useEffect(() => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            abn,
          }));

          setReady(abnError)

      }, [abn, abnError, setReady, setFormData]);
    
      useEffect(() => {
        const handleCheck = () => {
          abnLookup(debouncedAbn)
            .then(function(result) {
              if (result === '') {
                setAbnError(true);
              } else {
                setAbnError(false);
              }
            })
            .catch(function(error) {
              console.error(error);
            });
        };
    
        handleCheck();
      }, [debouncedAbn]);

    //Phone functions
    const[phone, setPhone ] = useState(formData.phone)
    const [phoneError] = useState(false)

    const handleChangePhone = (e) => {
        const value = e.target.value;
    
        setPhone(value);
    };

    useEffect(() => {
        if (phoneError) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone: "",
            }))
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone,
            })) 
        }
        
        
    }, [phone, phoneError, setFormData]);

    //Email functions
    const[email, setEmail ] = useState(formData.email)
    const [emailError] = useState(false)

    const handleChangeEmail = (e) => {
        const value = e.target.value;
    
        setEmail(value);
    };

    useEffect(() => {
        if (emailError) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: "",
            }))
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email,
            })) 
        }
        
        
    }, [email, emailError, setFormData]);

    //Website functions
    const[website, setWebsite ] = useState(formData.website)

    const handleChangeWebsite = (e) => {
        const value = e.target.value;
    
        setWebsite(value);
    };

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            website,
        }))         
    }, [website, setFormData]);

    //Logo functions
    const handleImageUpload = (base64) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            "logo": base64,
        }));
    }

    const handleRemoveImage =() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            "logo": "",
        }));
    }

    function CustomButton({ children, onClick }) {
        return (
          <button
            className="flex gap-1"
            onClick={onClick}
            >
            {children}
            <UserSquareIcon className="size-4 light-stroke"/> ロゴを追加
            
          </button>
        );
      }

    useEffect(() => {
        setReady(
            abnError
        )
    }, [setReady, abnError])

      return(
        <div className="flex flex-col w-full md:w-[20vw] justify-start items-start gap-8">
            <div className="w-full">
                <label className="font-inter font-semi-bold">
                    ビジネスネーム
                </label>
                <input
                    className='w-full h-[2rem] py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                    type="text"
                    value={formData.businessName}
                    placeholder='e.g. Mangetsu'
                    onChange={handleChangeName}
                />
            </div>
            <div className="w-full">
                <label className="font-inter font-medium">
                    ABN
                </label>
                <div className="w-full flex flex-row gap-3 justify-center items-center">
                    <input
                        className='w-full h-[2rem] py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                        type="text"
                        value={abn}
                        placeholder='e.g. 83914571673'
                        onChange={handleChangeAbn}
                    />
                    {!abnError ? (
                        <WarningSvg className='size-5'/>
                    ) : (
                        <CheckSvg className='size-5'/>
                    )}
                </div>
            </div>
           <div className="w-full">
                <label className="font-inter font-semi-bold">
                    電話番号
                </label>
                <input
                    className='w-full h-[2rem] py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                    type="text"
                    value={formData.phone}
                    placeholder='e.g. 0837445882'
                    onChange={handleChangePhone}
                />
            </div> 
            <div className="w-full">
                <label className="font-inter font-semi-bold">
                    Eメール
                </label>
                <input
                    className='w-full h-[2rem] py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                    type="text"
                    value={formData.email}
                    placeholder='e.g. example@mangetsu.com'
                    onChange={handleChangeEmail}
                />
            </div>
            <div className="w-full">
                <label className="font-inter font-semi-bold">
                    ウェブサイトリンク
                </label>
                <input
                    className='w-full h-[2rem] py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                    type="text"
                    value={formData.website}
                    placeholder='e.g. www.mangetsu.com.au'
                    onChange={handleChangeWebsite}
                />
            </div>
            <div className={`${formData.logo === "" ? "hidden" : "block relative"}`}>
                <button className="absolute top-1 right-1 bg-black/80 p-2 rounded-full" onClick={() => handleRemoveImage()}><CrossIcon className="white-stroke size-[0.4rem]"/></button>
                <img className="size-20 rounded-lg object-cover" src={formData.logo} alt="logo"></img>
            </div>
            <div className="flex font-inter font-semi-bold gap-4 text-[1.5vh] text-neutral-500">
                <div className='flex justify-center items-center h-[80%]'>
                    <ImageUploader 
                        onImageUpload={handleImageUpload}
                        label=""
                        buttonComponent={CustomButton}
                    />
                </div>
            </div>
        </div>
    )
}

export default BusinessDetalStep