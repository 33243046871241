import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'

// Steps
import NameStep from './NameStep';
import UsernameStep from './UsernameStep';
import PfpStep from './PfpStep';
import PasswordStep from './Password'
import EmailStep from './EmailStep';
import EmailVerification from './EmailVerification';
import PhoneStep from './Phone'
import PhoneVerification from './PhoneVerificaton'
import LoadingScreen from './components/LoadingScreen';

// Assets, Utils, Components and Contexts
import { useAuthContext } from '../../hooks/useAuthContext';
import { ReactComponent as NavLeft } from '../../assets/Cosmetics/svgs/nav-left.svg';
import ProgressIndicator from '../../utils/ProgressIndicator';
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading.svg'
import RefreshPrompt from '../../utils/RefreshPrompt';

const SignupForm = () => {
  const { dispatch } = useAuthContext()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    pfp: '',
    name: '',
    username: '',
    phone: '',
    privacy: false,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 8; // Increase the total steps by 1 for the verification step
  const apiUrl = process.env.REACT_APP_API_URL

  const [ready, setReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add a state for loading
  const [isSignupLoading, setIsSignupLoading] = useState(false)

  const handleRegistration = async (userData) => {
    setIsSignupLoading(true); // Set loading state to true

    try {
    const response = await fetch(`${apiUrl}/api/user/signup`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });

    if (response.ok) {
        console.log('Registration successful!');

        // Extract the JSON data from the response
        const data = await response.json();

        // Store the user data in localStorage
        localStorage.setItem('user', JSON.stringify(data));

        // Update the auth context (assuming you have a dispatch function from a context)
        dispatch({ type: 'LOGIN', payload: data });
    } else {
        console.error('Registration failed.');
    }
    } catch (error) {
    console.error('Registration error:', error);
    } finally {
    setIsSignupLoading(false); // Set loading state to false
    }
  };

  const sendEmailOTP = async () => {
    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(`${apiUrl}/api/user/email-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email }),
      });

      if (response.ok) {
        setCurrentStep(5);
      } else {
        throw new Error('Failed to generate OTP');
      }
 
    } catch (error) {
      console.error('Error generating OTP:', error);
      throw error;
    } finally {
        setIsLoading(false); // Set loading state to false
    } 
  }

  const sendPhoneOTP = async () => {
    setIsLoading(true); // Set loading state to true

    try {
      const response = await fetch(`${apiUrl}/api/user/phone-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: formData.phone }),
      });

      if (response.ok) {
        setCurrentStep(7);
      } else {
        throw new Error('Failed to generate OTP');
      }
 
    } catch (error) {
      console.error('Error generating OTP:', error);
      throw error;
    } finally {
        setIsLoading(false); // Set loading state to false
    }
  };

  const handleNext = (e) => {
    if (currentStep === 4) {
        e.preventDefault();
        // Handle verification code submission

        sendEmailOTP()
    } else if (currentStep === 6) {
        e.preventDefault();

        sendPhoneOTP()
    } else {
        setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep === 0) {
      window.location.href = '/';
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleVerificationSuccess = async () => {
    if (currentStep === 5) {
        setCurrentStep(currentStep + 1)
    }

    if (currentStep === 7) {
        setCurrentStep(8)
        await handleRegistration({...formData})
        window.location.href = '/'
        setFormData({
            email: '',
            password: '',
            pfp: '',
            name: '',
            username: '',
            phone: '',
            privacy: false,
            verified: false,
        });
    }
  };

  useEffect(() => {
    if (currentStep === 0) {
      setReady(formData.name !== '');
    }
    if (currentStep === 1) {
      setReady(formData.username !== '');
    }
    if (currentStep === 2) {
      setReady(formData.pfp !== '');
    }
    if (currentStep === 3) {
      setReady(formData.password !== '');
    }
    if (currentStep === 4) {
      setReady(formData.email !== '');
    }
    if (currentStep === 6) {
        setReady(formData.phone !== '');
    }

   }, [currentStep, formData]);

   const stepVariants = {
    initial: { x: '5vw', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '-5vw', opacity: 0 },
  };

  return (
    <div className="flex flex-col md:justify-center mt-8 md:mt-0 items-center px-10 h-[85vh]">
        {!(isSignupLoading) && 
            <button onClick={handlePrevious} className="absolute top-8 left-8 p-2">
                <NavLeft />
            </button>
        }
        {!isSignupLoading && 
          <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />

        }
      <div className="flex flex-col justify-between items-center mt-[4rem] w-screen h-full">
            {currentStep === 0 && (
            <motion.div
                className='h-full'
                key="step-0"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
              <NameStep formData={formData} setFormData={setFormData} />
            </motion.div>
            )}
            {currentStep === 1 && (
            <motion.div
                className='h-full'
                key="step-1"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
                <UsernameStep formData={formData} setFormData={setFormData} />
            </motion.div>
            )}
            {currentStep === 2 && (
            <motion.div
                className='h-full'
                key="step-2"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
                <PfpStep formData={formData} setFormData={setFormData} />
            </motion.div>
            )}
            {currentStep === 3 && (
            <motion.div
                className='h-full'
                key="step-3"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
                <PasswordStep formData={formData} setFormData={setFormData} />
            </motion.div>
            )}
            {currentStep === 4 && (
            <motion.div
                className='h-full'
                key="step-4"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                <EmailStep formData={formData} setFormData={setFormData} />
            </motion.div>
            )}
            {currentStep === 5 && (
            <motion.div
                className='h-full'
                key="step-5"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
                <EmailVerification formData={formData} setFormData={setFormData} onVerificationSuccess={handleVerificationSuccess}/>
            </motion.div>
            )}
            {currentStep === 6 && (
            <motion.div
                className='h-full'
                key="step-6"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
                <PhoneStep formData={formData} setFormData={setFormData} />
            </motion.div>
            )}
            {currentStep === 7 && (
            <motion.div
                className='h-full'
                key="step-7"
                variants={stepVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.1, ease: 'easeOut' }}
            >
                <PhoneVerification formData={formData} setFormData={setFormData} onVerificationSuccess={handleVerificationSuccess}/>
            </motion.div>
            )}
            {isSignupLoading && (
              <LoadingScreen />
            )}
            {!(currentStep === 7 || currentStep === 5 || isSignupLoading) && 
                <button
                disabled={!ready || isLoading }
                className={`fixed bottom-[4vh] flex flex-row justify-center items-center gap-2 rounded-full font-inter font-bold text-white py-5 w-[80%] md:w-[30vw] ${ready ? 'bg-red' : 'bg-neutral-500 disable'}`}
                onClick={handleNext}
                >
                {isLoading && <LoadingSvg className='animate-spin h-5 w-5'/>} 次
                </button>
            }
      </div>
      <RefreshPrompt isFormDirty={currentStep !== 8}/>
    </div>
  );
};

export default SignupForm;