import Map, { Marker } from 'react-map-gl';
import MapPin from '../assets/Cosmetics/pngs/mappin.png'

const MapSearcher = ({ items, viewport, onViewportChange }) => {

    return (
        <Map
            {...viewport}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            mapStyle="mapbox://styles/kk4w4i/clpfcmgsg004101px2xavhydd"
            style={{width: '100%', height: '100%'}}
            onMove={evt => {
                onViewportChange(evt.viewState);
            }}
        >

            {items.map(item => (
                <Marker
                    key={item._id}
                    latitude={item.locationLat}
                    longitude={item.locationLong}
                    offsetLeft={-20}
                    offsetTop={-10}
                >
                    <img className='h-[2.6rem]' src={MapPin} alt="📍"/>
                </Marker>
            ))}
        </Map>
    )
}

export default MapSearcher
