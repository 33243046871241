import React from 'react';
import { useLogout } from '../../hooks/useLogout';
import { ReactComponent as LogoutIcon } from '../../assets/Cosmetics/svgs/logout.svg'

const LogoutComponent = () => {
  const { logout } = useLogout()

    const handleLogoutInComponent = () => {
      window.location.href ='/'
      logout()
     }

  return (
    <div>
        <button className="flex flex-row gap-3 text-start font-inter font-bold text-[1.5vh] rounded-[10px] bg-neutral-100 px-6 py-4 w-full text-neutral-500/80" onClick={handleLogoutInComponent} type="button">
          <LogoutIcon className="light-stroke"/>
          ログアウト
        </button>
    </div>
  );
};

export default LogoutComponent;