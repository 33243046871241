export const openSpring = {
    type: "spring",
    stiffness: 500,
    damping: 40, // Lower damping value for a smoother ease-out
  };
  
  export const closeSpring = {
    type: "spring",
    stiffness: 600,
    damping: 40, // Higher damping value for a more pronounced ease-out
  };