import { ReactComponent as EmptyPfpIcon } from '../../assets/Cosmetics/svgs/emptypfp.svg'
import { useState } from 'react'
import ImageUploader from '../../components/ImageUploader'


const PfpStep = ({ formData, setFormData }) => {
    const [hover, setHover ] = useState()

    const handleImageUpload = (base64) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            "pfp": base64,
        }));
    }

    function CustomButton({ children, onClick }) {
        return (
          <button
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={`custom-button text-white rounded-full`}
            onClick={onClick}
          >
            {children}
            {formData.pfp !== "" ? (
                <img className="size-[15rem] rounded-full object-cover border-[1px]" src={formData.pfp} alt="pfp"></img>
            ) : (
                <EmptyPfpIcon className={`size-[15rem] ${hover ? "pfp-upload-h-stroke" : ""}`} />
            )}
            
          </button>
        );
      }

    return (

        <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-screen h-full md:w-[40vw]">
            <section className='flex flex-col gap-2 w-[80%] h-full'>
                <div className='flex flex-col h-full'>
                    <div>
                        <p className="text-[1.7rem] font-inter font-bold">プロフィール画像</p>
                        <p className="text-[1rem] font-inter font-medium text-neutral-400" >プロフィール画像を選択してください</p>
                    </div>
                    <div className='flex justify-center items-center h-[80%]'>
                        <ImageUploader 
                            onImageUpload={handleImageUpload}
                            label=""
                            buttonComponent={CustomButton}
                        />
                    </div>
                </div>                
            </section>
        </div>
    )

}

export default PfpStep





