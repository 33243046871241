import { Navigate } from 'react-router-dom'
import { useJobFormContext } from '../hooks/useJobFormContext'

function ProtectedRoute({ component: Component, requiredStep, forOnboarding, forBusinessListing, authorized, ...rest }) {
  const { currentStep } = useJobFormContext()
  
  if (forOnboarding) {
    return <Component {...rest} />
  }
  else if (forBusinessListing) {
    if (authorized) {
      return <Component {...rest} />
    }
  }
  else {
    return currentStep >= requiredStep ? <Component {...rest} /> : <Navigate to="/" />
  }
}

export default ProtectedRoute