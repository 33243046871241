import { useState, useEffect } from "react";
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import CountryCodeSelector from './components/CountryCodeSelector'
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading-black.svg'
import { ReactComponent as CheckSvg } from '../../assets/Cosmetics/svgs/check.svg'
import { ReactComponent as WarningSvg } from '../../assets/Cosmetics/svgs/warning.svg'

const PhoneStep = ({ formData, setFormData }) => {
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [fullPhoneNumber, setFullPhoneNumber] = useState('');  
    const [phoneError, setPhoneError] = useState(true);
    const [debouncePhone] = useDebounce(fullPhoneNumber, 500); // Debounce for 5 seconds
    const apiUrl = process.env.REACT_APP_API_URL;

    const { isLoading, data: isPhoneAvailable } = useQuery(
        ['checkEmailAvailability', debouncePhone],
         async () => {
            if (!debouncePhone) return false; // Check if phone number is 13 characters long

            const response = await fetch(`${apiUrl}/api/user/check-phone?phone=${debouncePhone}`);
            const data = await response.json();
            return data.available;
        },
        {
            enabled: !!debouncePhone,
            refetchOnWindowFocus: false,
        }
    );

    const handleChange = (e) => {
        const value = e.target.value;
        setPhone(value);

        const newFullPhoneNumber = countryCode + value;
        setFullPhoneNumber(newFullPhoneNumber);
    };

    const handleCountryChange = (code) => {
        setCountryCode(code);
        const newFullPhoneNumber = code + phone;
        setFullPhoneNumber(newFullPhoneNumber);
    };

    useEffect(() => {
        const isValidPhone = fullPhoneNumber.length === 12

        if (!isValidPhone || !isPhoneAvailable) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }

    }, [fullPhoneNumber, isPhoneAvailable])

    useEffect(() => {
        if (phoneError) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone: "",
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                phone: fullPhoneNumber,
            }));
        }

    }, [fullPhoneNumber, phoneError, setFormData]);
    
    return (
        <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-screen h-full md:w-[40vw]">
            <section className='flex flex-col gap-2 w-[80%] h-full'>
                <p className="text-[1.7rem] font-inter font-bold mb-5">電話番号登録</p>
                <section className='flex flex-col gap-1 mb-2'>
                    <p className="text-[1rem] font-inter font-medium text-neutral-800 mb-4">電話番号</p>
                    <CountryCodeSelector className="w-[30vw]" onCountryChange={handleCountryChange} />
                    <div className="relative flex flex-row items-center pr-6 justify-between">
                        <input
                        className='w-full h-[2rem] pl-2 py-4 placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                        type="text"
                        name="phone"
                        value={phone}
                        inputMode="numeric"
                        placeholder='04XXXXXXXX'
                        onChange={handleChange}
                        />
                        {isLoading ? (
                            <LoadingSvg className='animate-spin size-5 focus-stroke'/>
                        ) : phoneError ? (
                            <WarningSvg className='size-5'/>
                        ) : (
                            <CheckSvg className='size-5'/>
                        )}
                    </div>
                </section>
            </section>
        </div>
    )
}

export default PhoneStep