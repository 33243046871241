import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading-black.svg'
import { ReactComponent as CheckSvg } from '../../assets/Cosmetics/svgs/check.svg'
import { ReactComponent as WarningSvg } from '../../assets/Cosmetics/svgs/warning.svg'

const UsernnameStep = ({ formData, setFormData }) => {
    const [username, setUsername] = useState('')
    const [debouncedUsername] = useDebounce(username, 500); // Debounce delay of 500ms
    const [usernameError, setUsernameError] = useState(true)
    const apiUrl = process.env.REACT_APP_API_URL;

    const { isLoading, data: isUsernameAvailable } = useQuery(
        ['checkUsernameAvailability', debouncedUsername],
        async () => {
            if (!debouncedUsername) return true;

            const response = await fetch(`${apiUrl}/api/user/check-username?username=${debouncedUsername}`);
            const data = await response.json();
            return data.available;
        },
        {
            enabled: !!debouncedUsername,
            refetchOnWindowFocus: false,
        }
    );

    const handleChange = (e) => {
        const value = e.target.value;
        const filteredValue = value.replace(/[^a-zA-Z0-9_]/g, '');

        setUsername(filteredValue)
    };

    useEffect(() => {
        const isValidUsername = username.length > 2

        if (!isValidUsername || !isUsernameAvailable) {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    }, [username, isUsernameAvailable])

    useEffect(() => {
        if (usernameError) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                username: "",
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                username: username,
            }));
        }

    }, [username, usernameError, setFormData]);

    return (
        <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-full h-full md:w-[40vw]">
            <section className='flex flex-col gap-2 w-[80%]'>
                <p className="text-[1.7rem] font-inter font-bold">ユーザーネーム</p>
                <p className="text-[1rem] font-inter font-medium text-neutral-400">好きなユーザーネームを入力してください</p>
                <div className="relative flex flex-row items-center mt-3">
                    <input
                        className='w-full h-[2rem] text-[1.4rem] placeholder:text-neutral-400 py-6 focus:outline-none focus:ring-0 custom-caret'
                        type="text"
                        name="username"
                        value={username}
                        placeholder='e.g. hanako123'
                        onChange={handleChange}
                    />
                    {isLoading ? (
                        <LoadingSvg className='animate-spin size-5 focus-stroke'/>
                    ) : usernameError ? (
                        <WarningSvg className='size-5'/>
                    ) : (
                        <CheckSvg className='size-5'/>
                    )}
                </div>
                <p className="mt-3 text-[0.7rem] font-inter font-regular text-neutral-400">ユーザー名は公開されるため、個人情報を開示することなく、ユーザーアカウントを識別できます</p>
            </section>
        </div>
    );
};

export default UsernnameStep;
