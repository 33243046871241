import { createContext, useReducer } from "react"

export const BusinessContext = createContext()

export const businessReducer = (state, action) => {
    switch(action.type) {
        case 'SET_BUSINESSES':
            return {
                businesses: action.payload
            }
        case 'CREATE_BUSINESS':
            return {
                businesses: [action.payload, ...state.businesses]
            }
        case 'DELETE_BUSINESS':
            return {
                businesses: state.businesses.filter((w) => w._id !== action.payload._id)
            }
        case 'UPDATE_BUSINESS':
            return {
                ...state, 
                businesses: state.businesses.map(business => 
                    business._id === action.payload._id ? { ...business, ...action.payload } : business
                ) 
            }
        default: 
            return state
    }
}

export const BusinessContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(businessReducer, {
        businesses: []
    })

    return (
        <BusinessContext.Provider value={{ ...state, dispatch }}>
            { children }
        </BusinessContext.Provider>
    )
}