import { useEffect, useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import AccountHome from './AccountHome'
import { motion } from 'framer-motion'
import UserDetails from './UserDetails'
import LogoutComponent from '../Auth/LogoutComponent'

import {ReactComponent as NavLeft} from '../../assets/Cosmetics/svgs/nav-left.svg'

const AccountComponent = () => {
    const { user, dispatch } = useAuthContext()
    const [loading, setLoading] = useState(true)
    const [pageState, setPageState] = useState(1)

    useEffect(() => {
        const fetchAccountDetails = async () => {
            setLoading(true)
            const apiUrl = process.env.REACT_APP_API_URL
            try {
                const response = await fetch(`${apiUrl}/api/user/protected/account?id=${user.user_id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization' : `Bearer ${user.token}`
                    }
                })

                const json = await response.json()

                if (response.ok) {
                    dispatch({ type: 'GET_USER', payload: json})
                }
            } catch(err) {
                console.error(err)
            }
            
            setLoading(false)
        }

        fetchAccountDetails()

        // eslint-disable-next-line
    }, [])

    const stepVariants = {
        initial: { x: '5vw', opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: '-5vw', opacity: 0 },
      };

    return (
        <div className="flex flex-col md:flex-row h-full w-screen justify-between md:justify-start py-10 px-5 md:px-10">
            <div className='flex flex-col md:flex-row'>
                <div className='h-auto md:h-full flex flex-col justify-between'>
                    <div>
                        <div className='fixed top-3 right-1/2 translate-x-1/2 bg-neutral-400 h-[0.5vh] w-[9vw] rounded-full'></div>
                        <h1 className="font-inter font-semi-bold text-[2vh] text-center md:text-start md:font-bold md:text-[2.5vh]">アカウント</h1>
                        <p className="font-inter font-light text-neutral-400 text-[1.2vh] mb-5 text-center md:text-start">@{user.username}</p>
                        <div className='hidden md:block md:w-[20vw]'>
                            <AccountHome loading={loading} user={user} setPageState={setPageState}/>
                        </div>
                    </div>
                    <div className='hidden md:block'>
                        <LogoutComponent />
                    </div>
                </div>
                {pageState !== 1 && 
                        <button onClick={() => setPageState(1)} className="absolute top-10 left-3 p-2">
                            <NavLeft />
                        </button>
                    }
                {pageState === 1 && (
                    <motion.div
                        className='w-full block md:hidden'
                        key="step-1"
                        variants={stepVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.1, ease: 'easeOut' }}
                    >
                        <AccountHome loading={loading} user={user} setPageState={setPageState}/>
                    </motion.div>
                )}
                {pageState === 2 && (
                <motion.div
                    className='w-full block md:hidden'
                    key="step-2"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                    <UserDetails user={user} setPageState={setPageState}/>
                </motion.div> 
                )}

                
            </div>

            <div className='block md:hidden'>
                <LogoutComponent />
            </div>
        </div>
    )
}

export default AccountComponent