import { ReactComponent as CustomerServiceIcon } from './svgs/customer-service.svg'
import { ReactComponent as ElectricianIcon } from './svgs/electrician.svg'
import { ReactComponent as ManagementIcon } from './svgs/managment.svg'
import { ReactComponent as MechanicIcon } from './svgs/mechanic.svg'
import { ReactComponent as FinanceIcon } from './svgs/finance.svg'
import { ReactComponent as SoftwareIcon } from './svgs/software.svg'
import { ReactComponent as  MarketingIcon } from './svgs/marketing.svg'
import { ReactComponent as  AdminIcon } from './svgs/admin.svg'
import { ReactComponent as  PhotoIcon } from './svgs/photo.svg'
import { ReactComponent as  JournalistIcon } from './svgs/journalist.svg'
import { ReactComponent as  PlumberIcon } from './svgs/plumber.svg'
import { ReactComponent as  HouseIcon } from './svgs/house.svg'
import { ReactComponent as  EngineerIcon } from './svgs/engineer.svg'
import { ReactComponent as  LawyerIcon } from './svgs/lawyer.svg'
import { ReactComponent as  HealthIcon } from './svgs/health.svg'
import { ReactComponent as  ConsultingIcon } from './svgs/consulting.svg'

const JoblistingCategories = [
    { label: 'カスタマーサービス', SvgIcon: CustomerServiceIcon },
    { label: '電気技師', SvgIcon: ElectricianIcon },
    { label: 'マネージメント', SvgIcon: ManagementIcon },
    { label: '整備士', SvgIcon: MechanicIcon },
    { label: '金融', SvgIcon: FinanceIcon },
    { label: 'ソフトウェア', SvgIcon: SoftwareIcon },
    { label: 'マーケティング', SvgIcon: MarketingIcon },
    { label: 'アドミン', SvgIcon: AdminIcon },
    { label: '写真・ビデオ', SvgIcon: PhotoIcon },
    { label: 'ジャーナリスト', SvgIcon: JournalistIcon },
    { label: '配管工', SvgIcon: PlumberIcon },
    { label: 'ハウスケア', SvgIcon: HouseIcon },
    { label: '工学', SvgIcon: EngineerIcon },
    { label: '弁護士', SvgIcon: LawyerIcon },
    { label: '医療', SvgIcon: HealthIcon },
    { label: 'コンサルティング', SvgIcon: ConsultingIcon }
]

const JoblistingPositions = [
    { label: "カジュアル" },
    { label: "パート" },
    { label: "社員" } 
]

export { JoblistingCategories, JoblistingPositions }