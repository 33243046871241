import { useState, useEffect } from "react"
import { useBusinessContext } from "../hooks/useBusinessContext"
import BusinessCards from './BusinessListing/components/BusinessCard'
import ExpandedBusinessCard from "./BusinessListing/components/ExpandedBusinessCard"
import MapSearcher from "../components/MapSearcher"
import { WebMercatorViewport } from 'viewport-mercator-project'
import CategorySlider from "../components/CategorySlider"
import SkeletonCard from "../assets/Cosmetics/animations/SkeletonCard"
import AccordionFilter from "../components/Accordion"

import { ReactComponent as LeftArrow } from '../assets/Cosmetics/svgs/Larrow.svg'
import { ReactComponent as RightArrow } from '../assets/Cosmetics/svgs/Rarrow.svg'

const Businesses = ({ isMapHidden }) => {
    const [loading, setLoading] = useState(true)
    const [collapse, setCollapse] = useState(false)
    const { businesses, dispatch }  = useBusinessContext()
    const [viewport, setViewport] = useState({
        longitude: 153.021072,
        latitude: -27.470125,
        zoom: 13
    })
    const [mapBounds, setMapBounds] = useState({
        southwest: { lat: -27.623170761868428, lng: 152.70355478116852 },
        northeast: { lat: -27.320555370821672, lng: 153.33858921883148 }  
    });
      
    const [selectedCategory, setSelectedCategory] =useState('')

    const [selectedId, setSelectedId] = useState(null);

    const toggleExpanded = () => {
        setSelectedId(null);
    };

    const toggleOpen = (id) => {
        setSelectedId(id === selectedId ? null : id);
    };

    useEffect(() => {
        const fetchBusinesses = async () => {
            setLoading(true); // Set loading to true before making the request
    
            const apiUrl = process.env.REACT_APP_API_URL
            let url = `${apiUrl}/api/business`
            
            try {
                const response = await fetch(url)
                const json = await response.json()
    
                if (response.ok) {
                    dispatch({type: 'SET_BUSINESSES', payload: json})
                }
            } catch (error) {
                console.error(error);
                // Optionally set an error state here
            }
            
            setLoading(false); // Set loading to false after the request completes
        }
    
        fetchBusinesses();
    }, [dispatch])

    function calculateBounds(viewport) {
        const { latitude, longitude, zoom, width, height } = viewport
        
        const vp = new WebMercatorViewport({ longitude, latitude, zoom, width, height })
        const sw = vp.unproject([0, height])
        const ne = vp.unproject([width, 0])
    
        return {
            southwest: { lat: sw[1], lng: sw[0] },
            northeast: { lat: ne[1], lng: ne[0] }
        }
    }

    const onViewportChange = (newViewport) => {
        const bounds = calculateBounds({
            ...newViewport,
            width: window.innerWidth * 1,
            height: window.innerHeight * 1
        });
        setMapBounds(bounds)
        setViewport(newViewport)
    }

    const isBusinessInBounds = (business, bounds) => {
        return (
            business.locationLat >= bounds.southwest.lat &&
            business.locationLat <= bounds.northeast.lat &&
            business.locationLong >= bounds.southwest.lng &&
            business.locationLong <= bounds.northeast.lng
        )
    }

    const filteredBusinesses = businesses.filter(business => 
        (selectedCategory ? business.category === selectedCategory : true) &&
        (mapBounds ? isBusinessInBounds(business, mapBounds) : true)
    )

    const toggleGallery = () => {
        setCollapse(!collapse)
    }

    return (
        <div className="relative">
            <AccordionFilter className={`${isMapHidden ? "relative" : "absolute top-0 z-[2] drop-shadow-lg py-4 mt-[-1vh]"} block md:hidden w-full px-[4vw] py-2 bg-white`} count={filteredBusinesses.length} title="ビジネス">
                <div className="flex flex-col gap-4 w-full bg-white">
                    <CategorySlider type={'joblisting'} setSelectedCategory={setSelectedCategory}/>
                </div>
            </AccordionFilter>

            <div className={`${isMapHidden ? 'hidden' : ''} md:fixed md:flex md:right-0 h-[100vh] w-[100vw] md:z-[1]`}>
                <MapSearcher key={isMapHidden.toString()} items={filteredBusinesses} viewport={viewport} onViewportChange={onViewportChange}/>
            </div>

            <div className={`relative px-[4vw] md:px-5 h-[85vh] pb-5 md:fixed md:pt-5 md:top-[13vh] left-0 md:left-[4vw] flex flex-row md:h-[83vh] w-full transition-all ease-in-out duration-300 ${collapse ? "md:w-[24vw]": "md:w-[48vw]"}  bg-white md:rounded-[20px] md:drop-shadow-lg gap-2 md:z-[2]`}>
                <div className="flex flex-col h-full w-full">
                    <AccordionFilter className={`hidden md:block w-full py-2 bg-white`} count={filteredBusinesses.length} title="ビジネス">
                        <div className="flex flex-col gap-4 w-full bg-white">
                            <CategorySlider type={'joblisting'} setSelectedCategory={setSelectedCategory}/>
                        </div>
                    </AccordionFilter>
                    <div className="block overflow-y-auto mt-2 z-[4] h-full">
                        <div className={`grid grid-cols-1 w-[100%] md:flex gap-4 ${isMapHidden ? 'w-auto h-[69vh]' : ' hidden'}`}>
                            {loading ? (
                                <SkeletonCard isMapHidden={isMapHidden}/>
                            ) : (
                                <div className={`${isMapHidden ? 'w-full' : 'hidden'}`}>
                                    {filteredBusinesses.length > 0 ? (
                                            <div className={`${isMapHidden ? 'grid grid-cols-1' : 'hidden'} grid grid-cols-1 ${collapse ? "md:grid-cols-1" : "md:grid-cols-2"} gap-4 overflow-auto`}>
                                                    {filteredBusinesses.map((business) => (
                                                        <BusinessCards key={business._id} business={business} toggleOpen={toggleOpen} />
                                                    ))}                                            
                                            </div>
                                            
                                    ): (
                                        <div className="flex h-[57vh] justify-center items-center font-inter font-bold text-neutral-400">お探しのお仕事がありません</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <button className="hidden md:block" onClick={() => toggleGallery()}>
                    {!collapse ? (
                        <LeftArrow className="absolute -right-5 top-1/2 -translate-y-1/2 size-10 rounded-full p-3 bg-white z-[3]"/>
                    ) : (
                        <RightArrow className="absolute -right-5 top-1/2 -translate-y-1/2 size-10 rounded-full p-3 bg-white z-[3]"/>
                    )}
                </button>
            </div>

            {selectedId && 
                <div> 
                    <ExpandedBusinessCard className="z-[11]" business={businesses.find((business) => business._id === selectedId)} 
                    toggleExpanded={toggleExpanded}/>
                </div>
                
                
}
        </div>
    )
}

export default Businesses