
import DraggablePointMap from '../../components/DraggablePointMap'

const BusinessLocation = ({formData, setFormData}) => {
    return (
        <div className='w-full'>
            <DraggablePointMap formData={formData} setFormData={setFormData}/>
        </div>
    )
}

export default BusinessLocation