import { useState } from "react";
import { motion } from 'framer-motion'

// Steps
import CategoryStep from './CategoryStep'
import LoadingScreen from '../../assets/Cosmetics/animations/LoadingScreen';

// Context Utils Assets Imports
import { useJobFormContext } from "../../hooks/useJobFormContext"
import { ReactComponent as NavLeft } from '../../assets/Cosmetics/svgs/nav-left.svg';
import ProgressIndicator from '../../utils/ProgressIndicator';
import { ReactComponent as LoadingSvg } from '../../assets/Cosmetics/svgs/loading.svg'
import RefreshPrompt from '../../utils/RefreshPrompt';

const JobListingForm = () => {
    const { formData, setFormData, currentStep, setCurrentStep, ready } = useJobFormContext()
    const totalSteps = 6; // Increase the total steps by 1 for the verification step
    const [isLoading] = useState(false); // Add a state for loading
    const [isJobListingLoading] = useState(false)
    
    const stepVariants = {
        initial: { x: '5vw', opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: '-5vw', opacity: 0 },
    };

      const handleNext = (e) => {
        setCurrentStep(currentStep + 1);
      };

      const handlePrevious = () => {
        if (currentStep === 0) {
          window.location.href = '/';
        } else {
          setCurrentStep(currentStep - 1);
        }
      };

      return (
        <div className="flex flex-col md:justify-center mt-8 md:mt-0 items-center px-10 h-[85vh]">
                <button onClick={handlePrevious} className="absolute top-8 left-8 p-2">
                    <NavLeft />
                </button>
            <ProgressIndicator currentStep={currentStep} totalSteps={totalSteps} />

        <div className="flex flex-col justify-between items-center mt-[4rem] w-screen h-full">
                {currentStep === 0 && (
                <motion.div
                    className='h-full'
                    key="step-0"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                    <CategoryStep formData={formData} setFormData={setFormData} />
                </motion.div>
                )}
                {currentStep === 1 && (
                <motion.div
                    className='h-full'
                    key="step-1"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                </motion.div>
                )}
                {currentStep === 2 && (
                <motion.div
                    className='h-full'
                    key="step-2"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                </motion.div>
                )}
                {currentStep === 3 && (
                <motion.div
                    className='h-full'
                    key="step-3"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                </motion.div>
                )}
                {currentStep === 4 && (
                <motion.div
                    className='h-full'
                    key="step-4"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                    >
                </motion.div>
                )}
                {currentStep === 5 && (
                <motion.div
                    className='h-full'
                    key="step-5"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                </motion.div>
                )}
                {currentStep === 6 && (
                <motion.div
                    className='h-full'
                    key="step-6"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                </motion.div>
                )}
                {currentStep === 7 && (
                <motion.div
                    className='h-full'
                    key="step-7"
                    variants={stepVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 0.1, ease: 'easeOut' }}
                >
                </motion.div>
                )}
                {isJobListingLoading && (
                <LoadingScreen />
                )}
                {!(currentStep === 7 || currentStep === 5 || isJobListingLoading) && 
                    <button
                    disabled={!ready || isLoading }
                    className={`fixed bottom-[4vh] flex flex-row justify-center items-center gap-2 rounded-full font-inter font-bold text-white py-5 w-[80%] md:w-[30vw] ${ready ? 'bg-red' : 'bg-neutral-500 disable'}`}
                    onClick={handleNext}
                    >
                    {isLoading && <LoadingSvg className='animate-spin h-5 w-5'/>} 次
                    </button>
                }
        </div>
        <RefreshPrompt isFormDirty={currentStep !== 8}/>
        </div>
      )
}

export default JobListingForm