import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonCard = ({ isMapHidden }) => {
  return (
    <div className={`${isMapHidden ? 'grid grid-cols-1' : 'hidden'} grid grid-cols-1 md:grid-cols-2 gap-4 overflow-auto md:w-[100%]`}>
      <Skeleton height={"19rem"} borderRadius={"15px"}/>
      <Skeleton height={"19rem"} borderRadius={"15px"}/>
      <Skeleton height={"19rem"} borderRadius={"15px"}/>
      <Skeleton height={"19rem"} borderRadius={"15px"}/>
      <Skeleton height={"19rem"} borderRadius={"15px"}/>
      <Skeleton height={"19rem"} borderRadius={"15px"}/>
    </div>
  );
};

export default SkeletonCard;