import { useEffect } from 'react';

const RefreshPrompt = ({ isFormDirty }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    const handlePopState = (event) => {
      if (isFormDirty && !event.state) {
        event.preventDefault();
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
        const shouldNavigate = window.confirm(confirmationMessage);
        if (!shouldNavigate) {
          window.history.pushState(null, null, window.location.href);
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isFormDirty]);

  return null;
};

export default RefreshPrompt;