import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const AccountSkeleton = () => {
  return (
    <div className='flex flex-row items-center border-[1px] border-neutral-300 w-full p-4 rounded-[15px] gap-4'>
        <Skeleton height={'7vh'} width={'7vh'} borderRadius={"50%"}/>
        <section className='flex flex-col justify-center h-full w-full'>
            <Skeleton height={'2.2vh'} width={'50%'} borderRadius={"5px"}/>
            <Skeleton height={'1.8vh'} width={'70%'} borderRadius={"5px"}/>
        </section>
    </div>
  );
};

export default AccountSkeleton;