import { Link } from "react-router-dom"

const Footer = ( { isMapHidden }) => {
    return (
        <div>
            {isMapHidden && 
                <div className={`flex flex-col bg-gray-100 px-7 py-7 md:px-[9vw] md:py-10`}>
                    {/* Link columns*/}
                    <div className="flex mb-20">
                        <div className="flex flex-col gap-1">
                            <label className="font-medium mb-2">サポート</label>
                            <Link className="text-[0.85rem]">マンゲツの使い方</Link>
                            <Link className="text-[0.85rem]" >お問い合わせ</Link>
                        </div>
                    </div>

                    {/* Company and socials footer */}
                    <div className="flex flex-col gap-2 md:block md:relative text-[0.8rem]">
                        <div className="flex md:absolute md:right-0">
                            <Link>Facebook</Link> ・ <Link>X</Link> ・ <Link>Instgram</Link>
                        </div>
                        <div className="flex md:absolute md:left-0">
                            © 2024 Mangetsu, Inc. ・ <Link>プライバシー</Link> ・ <Link>ターム</Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Footer