import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import App from './App';
import { JobContextProvider } from './context/JobContext';
import { AuthContextProvider } from './context/AuthContext';
import { BrowserRouter} from 'react-router-dom';
import ScrollToTop from './assets/Cosmetics/animations/ScrollTop';
import { BusinessContextProvider } from './context/BusinessContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

const Root = () => {

  return (
    <AuthContextProvider>
      <JobContextProvider>
        <BusinessContextProvider>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BusinessContextProvider>
      </JobContextProvider>
    </AuthContextProvider>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <ScrollToTop />
      <Root />
    </BrowserRouter>
  </React.StrictMode>
);

