const NameStep = ({ formData, setFormData }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    return (
        <div className="flex flex-col rounded-[10px] justify-start items-center gap-4 p-5 w-screen h-full md:w-[40vw]">
            <section className='flex flex-col gap-2 w-[80%]'>
                <p className="text-[1.7rem] font-inter font-bold">名前</p>
                <p className="text-[1rem] font-inter font-medium text-neutral-400" >名前を入力してください</p>
                <input
                    className='w-full mt-3 h-[2rem] text-[1.4rem] placeholder:text-neutral-400 py-6 focus:outline-none focus:ring-0 custom-caret'
                    type="text"
                    name="name"
                    value={formData.name}
                    placeholder='e.g. Satou Hanako'
                    onChange={handleChange}
                />
                <p className="mt-3 text-[0.7rem] font-inter font-regular text-neutral-400">アカウントプライバシー設定をオンにする場合、名前は公開されません</p>
            </section>
        </div>
    )
}

export default NameStep