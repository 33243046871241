import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import debounce from 'lodash/debounce';

import MakerPin from '../assets/Cosmetics/webp/location-pin.webp';

const DraggablePointMap = ({formData, setFormData}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(153.021072);
  const [lat, setLat] = useState(-27.470125);
  const [address, setAddress] = useState(formData.address);
  const [locationLat, setLocationLat] = useState(formData.locationLat)
  const [locationLong, setLocationLong] = useState(formData.locationLong)

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/kk4w4i/clpfcmgsg004101px2xavhydd',
      center: [lng, lat],
      zoom: 12
    });

    // Add geocoder control
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false
    });

    map.current.addControl(geocoder);

    geocoder.setInput(address);

    map.current.on('load', () => {
        map.current.loadImage(MakerPin, (error, image) => {
          if (error) throw error;
          map.current.addImage('custom-marker', image);
  
          const markerElement = document.createElement('div');
          markerElement.className = 'custom-marker';
          markerElement.style.backgroundImage = `url(${MakerPin})`;
          markerElement.style.width = '25px';
          markerElement.style.height = '25px';
          markerElement.style.backgroundSize = 'cover';
  
          const marker = new mapboxgl.Marker({
            draggable: true,
            element: markerElement,
          })
            .setLngLat([lng, lat])
            .addTo(map.current);
  
          // Update marker position on drag
          marker.on('dragend', () => {
            const lngLat = marker.getLngLat();
            setLng(lngLat.lng);
            setLat(lngLat.lat);
            updateAddress(lngLat);
          });
  
          // Update marker position when map is moved
          map.current.on('move', () => {
            const lngLat = map.current.getCenter();
            marker.setLngLat(lngLat);
            setLng(lngLat.lng);
            setLat(lngLat.lat);
          });
        });
      });

    // Debounce the address update
    const debouncedUpdateAddress = debounce(async (lngLat) => {
        try {
          const data = await updateAddress(lngLat);
          const updatedAddress = data.features[0].place_name;
          setAddress(updatedAddress);
          setLocationLat(lngLat.lat)
          setLocationLong(lngLat.lng)
          geocoder.setInput(updatedAddress);
        } catch (error) {
          console.error('Error updating address:', error);
        }
      }, 1000);
      
    // Trigger address update on moveend event
    map.current.on('moveend', () => {
      const lngLat = map.current.getCenter();
      debouncedUpdateAddress(lngLat);
    });

    // Clean up on unmount
    return () => {
      map.current.remove();
      debouncedUpdateAddress.cancel();
    };
    
    // eslint-disable-next-line
  }, []);

  const updateAddress = async (lngLat) => {
    return fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${mapboxgl.accessToken}`
    ).then(response => response.json());
  };

  useEffect(() => {
    
        setFormData((prevFormData) => ({
            ...prevFormData,
            address,
            locationLat,
            locationLong
        }))    

}, [address, locationLat, locationLong, setFormData]);

  return (
    <div className='fixed top-0 w-full'>
      <div ref={mapContainer} style={{ height: '100vh', borderRadius: '0px'}} />
    </div>
  );
};

export default DraggablePointMap;