import Lottie from "lottie-react"
import LoadingAnimation from '../../../assets/Cosmetics/json/loading-logo.json'

const LoadingScreen = () => {

    return (
        <div className="flex flex-col h-[70vh] w-screen justify-center items-center gap-4">
            <Lottie className="size-[15vh]" animationData={LoadingAnimation} speed={2}/>
            <p className="font-bold text-neutral-400">アカウントを制作してます</p>
        </div>
    )
}

export default LoadingScreen