import React from 'react';
import { ReactComponent as NavLeft } from '../../assets/Cosmetics/svgs/nav-left.svg'
import { useLocation } from 'react-router-dom';
import { ReactComponent as AtIcon } from '../../assets/Cosmetics/svgs/at-icon.svg'
import { ReactComponent as ShieldIcon } from '../../assets/Cosmetics/svgs/shield-check.svg'
import { ReactComponent as PrivateIcon } from '../../assets/Cosmetics/svgs/privacy.svg'


const SignUpComponent = ({ isSignUpOpen, setIsSignUpOpen, signinMode, setSigninMode}) => {
  const location = useLocation()

  const handleSignIn = (e) => {
    window.location.href = '/onboarding/account-register'

    if (location.pathname === '/onboarding/account-register') {
      setIsSignUpOpen(!isSignUpOpen)
    }
  }

  return (
    <div className='flex flex-col items-center pt-10 h-[87vh] md:gap-20'>
      <div className='flex flex-col gap-2 items-center md:w-[30vw]'>
          <button onClick={() => setSigninMode(!signinMode)} className='absolute top-8 left-8 p-2'>
            <NavLeft />
          </button> 
          <label className='text-[0.9rem] font-inter absolute top-9 text-center'>登録する</label>
        <div className='flex flex-col gap-4 mb-5'>
          <label className='text-[1.8rem] font-inter font-bold'>登録を簡単に、安全に</label>
          <p className='text-[0.75rem] font-inter text-gray-700'>マンゲツを安全の場所にするため、投稿や、求人応募、宿泊などの機能を使用する場合、IDの身分証明が必要となってきます。</p>
        </div>
        <div className='flex flex-row gap-4 p-4 border rounded-[15px]'>
          <AtIcon className="size-7"/>
          <div className='flex flex-col gap-1'>
            <label className='text-[0.9rem] font-bold md:text-[1.1rem]'>メール登録</label>
            <p className='text-[0.6rem] md:text-[0.8rem]'>アカウントを確認し、不正なメールアドレスの使用を防ぐため、メールアドレスに確認コードを送信します</p>
          </div>
        </div>
        <div className='flex flex-row gap-4 p-4 border rounded-[15px] md:w-[30vw]'>
          <ShieldIcon className="size-5 md:size-6"/>
          <div className='flex flex-col gap-1'>
            <label className='text-[0.9rem] font-bold md:text-[1.1rem]'>パスワード設定</label>
            <p className='text-[0.6rem] md:text-[0.8rem]'>アカウントの安全を守るために、長くて複雑なパスワードを設定しましょう</p>
          </div>
        </div>
        <div className='flex flex-row gap-4 p-4 border rounded-[15px]'>
          <PrivateIcon className="size-7"/>
          <div className='flex flex-col gap-1'>
            <label className='text-[0.9rem] font-bold md:text-[1.1rem]'>プライバシー重視</label>
            <p className='text-[0.6rem] md:text-[0.8rem]'>電話番号、メールアドレス、自宅住所などの個人情報は一般に公開されず、適切に管理、保護されます</p>
          </div>
        </div>
      </div>
        
      <div className='fixed bottom-[4vh] flex flex-col gap-2'>
        <p className='text-center text-[0.55rem] md:text-[0.7rem] font-light font-inter text-gray-500'>進むことにより、タームおよびプライバシーポリシーに同意したことになります</p>
        <button onClick={handleSignIn} className='w-[90vw] py-5 bg-red rounded-full text-white font-inter font-bold md:w-[28vw]'>登録する</button>
      </div>
    </div>
  );
};

export default SignUpComponent;
