import { motion } from 'framer-motion';
import { ReactComponent as SaveIcon } from '../../../assets/Cosmetics/svgs/save.svg'
import { openSpring, closeSpring } from "../../../assets/Cosmetics/animations/springs";
import { Cross1Icon } from '@radix-ui/react-icons';


const ExpandedJobCard = ({ job, toggleExpanded }) => {
  const expandedCardVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: openSpring },
    exit: { opacity: 0, y: -50, transition: closeSpring },
  };

    const date = new Date(job.updatedAt);
    const nlBEFormatter = new Intl.DateTimeFormat('nl-BE');

  return (
    <motion.div
      variants={expandedCardVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      layoutId={job._id}
      className="fixed top-0 flex flex-col h-[100vh] w-[100vw] z-[50] bg-white"
      onClick={(e) => e.stopPropagation()}
    >
        <div className='flex flex-col relative bg-white p-3 md:px-[30rem]'>
            {/* upper */}
            <div className='relative flex flex-col border border-neutral-200 rounded-[10px] w-full gap-1 mb-2 h-[15rem]'>
                <div className='flex flex-row gap-4 p-4'>
                    <img className='size-[5vh] object-cover border border-none rounded-[8px]' src={job.image} alt={"brand-logo"}/>
                    <section>
                        <h1 className='text-start font-inter font-bold line-clamp-1 text-[1.1rem]'>{job.title}</h1>
                        <p className='text-start font-inter font-medium line-clamp-1 text-[0.8rem] text-neutral-500'>{job.businessName}</p>
                    </section>
                </div>
                <div className='flex flex-row gap-1 pl-6 text-[0.7rem] line-clamp-1 truncate items-center'>
                    <p className='font-inter text-neutral-400'>
                        時給 ${job.minRate}~${job.maxRate}
                    </p>
                    <p className='font-inter text-neutral-400 font-bold'>・</p>
                    <p className='bg-orange-200 text-amber-500 px-2 py-1 rounded-full font-inter'>
                        {job.position}
                    </p>
                    <p className='font-inter text-neutral-400 font-bold'>・</p>
                    <p className='bg-red/20 text-red/50 px-2 py-1 rounded-full'>
                        {job.category}
                    </p>
                </div>
                <p className='absolute bottom-3 right-3 font-inter font-semi-bold text-[0.7rem] text-neutral-500'>最終更新日: {nlBEFormatter.format(date)}</p>
                <button onClick={toggleExpanded} className='absolute top-3 right-3 p-2 bg-neutral-200 rounded-full'>
                    <Cross1Icon/>
                </button>
            </div>
            {/* lower */}
            <div className='flex flex-row justify-between items-center w-full gap-3'>
                <div className='flex flex-col pl-1 items-start h-full text-start text-[0.8rem] text-neutral-400 w-[75%]'>
                    {job.location}
                </div>
                <button className='group bg-red/20 rounded-full p-2'>
                    <SaveIcon className='size-auto red-stroke'/>
                </button>
            </div>
        </div>
    </motion.div>
  );
};

export default ExpandedJobCard;