import { useState, useEffect } from 'react';
import { useJobFormContext } from '../../hooks/useJobFormContext'
import { JoblistingCategories } from '../../assets/categories/jobCategories'
import { useAuthContext } from '../../hooks/useAuthContext';
import FormNavigation from '../../components/FormNavigation';

const CategoryStep = () => {
    const { user } = useAuthContext()
    const { formData, updateFormData } = useJobFormContext()
    const [focusIndex, setFocusIndex] = useState(null);
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);

    let categories = JoblistingCategories;

    useEffect(() => {
        // Check if formData.category exists in the categories array
        const categoryIndex = categories.findIndex(item => item.label === formData.category);
        if (categoryIndex !== -1) {
            setFocusIndex(categoryIndex);
            setSelectedCategoryIndex(categoryIndex);
        }
    }, [formData.category, categories]);

    const handleCategoryClick = (category, index) => {
        // Check if the category is already selected
        if (selectedCategoryIndex === index) {
            // If so, deselect it
            setSelectedCategoryIndex(null);
            updateFormData({ category: "" });
            setFocusIndex(null)
        } else {
            // Otherwise, select the new category
            setSelectedCategoryIndex(index);
            setFocusIndex(index)
            updateFormData({ category });
        }
    };

    return (
        user ? (
            <div className='flex flex-col justify-start items-start gap-[4vh] md:h-[85vh] px-10 md:px-10 pb-[5vh] md:pb-0 w-screen'>
                <p className="text-[1.7rem] font-inter font-bold">名前</p>
                <label className='flex text-[1.4rem] md:mt-0 md:text-[2rem] font-medium text-center'>この仕事を最もよく表しているのは次のうちどれでしょう?</label>
                <div className='flex flex-col justify-center w-full md:w-auto'>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-[2rem]'>
                        {categories.map((item, index) => (
                            <button
                                className={`flex flex-col gap-4 px-4 md:px-6 pt-5 md:pt-4 pb-5 md:pb-8 rounded-[10px] md:hover:drop-shadow-lg transition-all ease-out duration-0.5 ${focusIndex === index ? 'bg-white drop-shadow-lg' : 'bg-neutral-200'}`}
                                key={index}
                                onClick={() => handleCategoryClick( item.label, index)}
                            >
                                <item.SvgIcon className={"size-6 md:size-5"}/>
                                <p className='font-inter text-[0.7rem] md:text-[1rem]'>{item.label}</p>
                            </button>
                        ))}
                    </div>
                </div>
                <FormNavigation />
            </div> ) :
        (
            <p>Please sign in or register!</p>
        )
    )
}

export default CategoryStep
