 import { useState, useEffect } from "react"
 import ImageUploader from "../../components/ImageUploader"
 import { Drawer } from 'vaul'
 
 import { JoblistingCategories } from '../../assets/categories/jobCategories';
 import {ReactComponent as CrossIcon } from '../../assets/Cosmetics/svgs/cross.svg'
 import { ReactComponent as ImageIcon } from '../../assets/Cosmetics/svgs/image.svg'
 import { ReactComponent as TagIcon } from '../../assets/Cosmetics/svgs/tag.svg'

 const BusinessInfo = ({formData, setFormData}) => {

    // Category functions
    let cateogryList = JoblistingCategories
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
    const [focusIndex, setFocusIndex] = useState(null);
    const [category, setCategory] = useState(formData.category)

    const handleCategoryClick = (category, index) => {
        // Check if the category is already selected
        if (selectedCategoryIndex === index) {
            // If so, deselect it
            setSelectedCategoryIndex(null);
            setFocusIndex(null)
            setCategory('')
        } else {
            // Otherwise, select the new category
            setSelectedCategoryIndex(index);
            setFocusIndex(index)
            setCategory(category)
        }
    };

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            category,
        }))         
    }, [category, setFormData]);

    // Description functions
    const[description, setDescription] = useState(formData.description)

    const handleChangeDescription = (e) => {
        const value = e.target.value
        
        setDescription(value)
    }

    useEffect(() => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            description,
        }))         
    }, [description, setFormData]);

    // Photo functions
    const handleImageUpload = (newImage) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          photos: [...prevFormData.photos, newImage],
        }));
      };

      const handleRemoveImage = (index) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          photos: prevFormData.photos.filter((_, i) => i !== index),
        }));
      };

    function CustomButton({ children, onClick }) {
        return (
          <button
            className="flex gap-2 items-center text-[0.8rem]"
            onClick={onClick}
            >
            {children}
            <ImageIcon className="size-4 light-stroke"/> 写真を追加
            
          </button>
        );
      }

    return (
        <div className="flex flex-col w-full justify-center items-start gap-5">
            <div>
                <h1 className="text-[1.7rem] font-inter font-bold">詳細</h1>
                <p className="text-[1rem] font-inter font-medium text-neutral-400" >説明文、カテゴリー、写真を追加してください</p>
            </div>
            <div className="w-full">
                <textarea
                    className='w-full min-h-[9rem] max-h-[20rem] py-2 px-3 bg-neutral-200 rounded-lg placeholder:text-neutral-400 border-gray-200 focus:border-neutral-500 focus:outline-none focus:ring-0 custom-caret'
                    type="text"
                    value={formData.description}
                    placeholder=''
                    onChange={handleChangeDescription}
                />
            </div>
            <div className={`${formData.photos.length === 0 ? "hidden" : "flex flex-row gap-0 overflow-x-auto w-full"}`}>
                {formData.photos.map((photo, index) => (
                    <div key={index} className="relative flex-none w-[10rem] h-[6rem] mr-3">
                        <img className="w-full h-full object-cover rounded-lg object-cover" src={photo} alt="Couldn't load pictures" />
                        <button
                        className="absolute top-1 right-1 p-2 bg-black/80 rounded-full"
                        onClick={() => handleRemoveImage(index)}
                        >
                        <CrossIcon className="white-stroke size-[0.4rem]"/>
                        </button>
                  </div>
                 ))}
            </div>
            <div className="flex font-inter font-semi-bold gap-5 text-[1.5vh] text-neutral-500/80 items-center">
                <div className='flex justify-center items-center h-[80%]'>
                    <ImageUploader 
                        onImageUpload={handleImageUpload}
                        label=""
                        buttonComponent={CustomButton}
                    />
                </div>
                <Drawer.Root>
                    <Drawer.Trigger >
                        {formData.category === '' ? (
                            <div className="flex gap-1 items-center py-1 text-[0.8rem]">
                                <TagIcon className="size-4 light-stroke"/> カテゴリー
                            </div>
                        ) : (
                            <div className="px-4 py-1 rounded-full text-[0.8rem] text-red/50 bg-red/20 border-red/20">
                                {category}
                            </div>
                        )
                        }
                        
                    </Drawer.Trigger>
                    <Drawer.Portal>
                        <Drawer.Overlay className="fixed inset-0 bg-black/40 z-[10]" />
                        <Drawer.Content className="flex justify-center fixed bottom-0 z-[11] focus:outline-none focus:ring-0 w-full md:pb-[5vh]">
                            <div className="flex bg-white p-5 w-full rounded-[30px] mb-5 mx-5">
                                <div className="w-full flex flex-col gap-5 p-3">
                                    <h1 className="text-[1.2rem] font-inter font-semi-bold">カテゴリー</h1>
                                    <div className="flex flex-wrap w-full gap-2 ">
                                        {cateogryList.map((item, index) => (
                                            <button 
                                                key={index} 
                                                className={`px-4 py-1 rounded-full border  text-[0.8rem] ${focusIndex === index ? 'text-red/50 bg-red/20 border-red/20' : 'text-neutral-500 border-neutral-500'}`}
                                                onClick={() => handleCategoryClick(item.label, index)}
                                            >
                                                {item.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Drawer.Content>
                    </Drawer.Portal>
                </Drawer.Root>
            </div>
        </div>
    )
 }

 export default BusinessInfo