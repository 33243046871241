// Assets
import AccountSkeleton from '../../assets/Cosmetics/animations/AccountSkeleton'
import { ReactComponent as NavRight } from '../../assets/Cosmetics/svgs/nav-right.svg'
import { ReactComponent as VertDots } from '../../assets/Cosmetics/svgs/vert-dots.svg'
import { ReactComponent as UploadIcon } from '../../assets/Cosmetics/svgs/upload.svg'
import { ReactComponent as AdIcon } from '../../assets/Cosmetics/svgs/ads.svg'
import { ReactComponent as SaveIcon } from '../../assets/Cosmetics/svgs/save.svg'
import { ReactComponent as TermsIcon } from '../../assets/Cosmetics/svgs/tsandcs.svg'
 
const AccountHome = ({ loading, user, setPageState}) => {

    return (
        <div className='w-auto'>
                {loading ? (
                    <AccountSkeleton />
                ) : (
                    <button onClick={() => setPageState(2)} className='flex flex-row items-center border-[1px] border-neutral-300 w-full p-4 rounded-[15px] gap-4 mb-5'>
                        <img className='size-[7vh] rounded-full object-cover border-[1px]' src={user.pfp} alt="NaN"/> 
                        <section className='flex flex-col justify-center h-full w-full'>
                            <p className='text-start font-inter font-medium text-[2.3vh]'>{user.name}</p>
                            <p className='text-start font-inter font-regular text-[1.5vh] text-neutral-400'>{user.email}</p>
                        </section>
                        <NavRight className="fill-black size-[4vh]"/>
                    </button>
                )}
                <div className='flex flex-col w-full justify-start px-2 gap-4 mt-7'>
                    <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700 border-b pb-4 border-neutral-100'>
                        <div className='flex flex-row items-center gap-3'>
                            <UploadIcon className="hover-stroke"/>
                            投稿
                        </div>
                        <NavRight className="fill-neutral-400 size-[2.5vh]"/>
                    </button>
                    <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700 border-b pb-4 border-neutral-100'>
                        <div className='flex flex-row items-center gap-3'>
                            <AdIcon className="hover-stroke"/>
                            宣伝
                        </div>
                        <NavRight className="fill-neutral-400 size-[2.5vh]"/>
                    </button>
                    <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700 border-b pb-4 border-neutral-100'>
                        <div className='flex flex-row items-center gap-3'>
                            <SaveIcon className="hover-stroke"/>
                            保存
                        </div>
                        <NavRight className="fill-neutral-400 size-[2.5vh]"/>
                    </button>
                    <button className='flex justify-between items-center text-start font-inter font-semi-bold text-neutral-700 pb-4 border-neutral-100 pr-2'>
                        <div className='flex flex-row items-center gap-3'>
                            <TermsIcon className="hover-stroke"/>
                            プライバシー
                        </div>
                        <VertDots className="light-stroke h-[1.5vh]"/>
                    </button>
                </div>
            </div>
    )
}

export default AccountHome